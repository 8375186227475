import React, { Fragment } from 'react';

export const renderTextWithLineBreaks = text => {
    return text && text.split('\n').map((line, index) => (
        <Fragment key={index}>
            {line}
            <br />
        </Fragment>
    ));
};

export const renderTextWithLineBreaksAndSymbolColor = text => {
    return text && text.split('\n').map((line, index) => (
        <Fragment key={index}>
            {line.split(/(♦︎|♥)/).map((part, i) =>
                part === '♦︎' || part === '♥' ? (
                    <span key={i} style={{ color: 'red' }}>
                        {part}
                    </span>
                ) : (
                    part
                )
            )}
            <br />
        </Fragment>
    ));
};

export const isFourDigitString = str => {
    const pattern = /^\d{4}$/;
    return pattern.test(str);
};

export const trimString = (str, allowedLength) => {
    if (str.length > allowedLength) {
      return str.substring(0, allowedLength) + '...';
    }
    return str;
};

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// export const breakLongWord = ( inputString, breakLength ) => {
//     const chunks = inputString.split(/(\s+)/); // Split by spaces while preserving spaces as chunks
//     const wrappedChunks = chunks.map((chunk, index) => {
//         if (chunk.trim() !== '' && chunk.length > breakLength) {
//             return (
//             <span key={index} style={{ wordBreak: 'break-all' }}>
//                 {chunk}
//             </span>
//             );
//         }
//         return chunk;
//     });
  
//     return <div style={{ wordBreak: 'break-word' }}>{wrappedChunks}</div>;
// };

export const breakLongWord = (inputString, breakLength, linkStyle) => {
    if (!linkStyle) {
        linkStyle = {};
    }
    // Split by spaces while preserving spaces as chunks
    const chunks = inputString.split(/(\s+)/);

    // Regular expression to match links
    const linkRegex = /(?:https?|ftp):\/\/[\n\S]+/g;

    // Iterate over chunks and identify links
    const wrappedChunks = chunks.map((chunk, index) => {
        // Check if the chunk is a link
        if (chunk.match(linkRegex)) {
            // If it's a link, return it as a separate chunk
            return (
                <a key={index} href={chunk} style={linkStyle} target="_blank" rel="noopener noreferrer">
                    {chunk}
                </a>
            );
        } else if (chunk.trim() !== '' && chunk.length > breakLength) {
            // If it's not a link but longer than breakLength, wrap it with span
            return (
                <span key={index} style={{ wordBreak: 'break-all' }}>
                    {chunk}
                </span>
            );
        }
        // Otherwise, return the chunk as is
        return chunk;
    });

    return <div style={{ wordBreak: 'break-word' }}>{wrappedChunks}</div>;
};

export const formatMinutes = (totalMinutes, language) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (language === 'ru') {
        return `${hours}ч:${minutes}м`;
    }
    return `${hours}h:${minutes}m`;
}

export const toUrlString = (title) => {
    return title
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
}