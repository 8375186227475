import React, { useEffect, useState } from 'react';

// import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { filter, size } from 'lodash';

import {
  Button, Input, Media, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';

import moment from 'moment';

import {
  getAllHintsOld
} from '../../../actions/admin';
import {
  toggleShowHint
} from '../../../actions/member';
import * as constants from '../../../constants/constants';
import closeImg from '../../../images/icons/close.png';

const Hints = ({ fontMode }) => {

  const { i18n, t } = useTranslation('common');
  const dispatch = useDispatch();

  const [openHintModal, setOpenHintModal] = useState(true);
  const [userShowHint, setUserShowHint] = useState(false);
  const [dailyTipCheck, setDailyTipCheck] = useState(false);
  const [randomCount, setRandomCount] = useState(0);
  const [devMode, setDevMode] = useState(false);

  const showHint = useSelector(state => state.member?.showHint || false);
  const checkDailyTip = useSelector(state => state.member?.checkDailyTip || null);
  const { hostname } = window.location;

  const tDay = (new Date()).getDay() + 2;
  const allOldHints = useSelector(state => state.admin.allOldHints || []);
  const filteredHints = size(allOldHints) > 0 ? filter(allOldHints, (item) => {
    return (item.showOptions === constants.SHOW_HINT_EVERY_DAY || item.showOptions === tDay.toString()) && item.devMode === devMode;
  }) : [];


  // console.log('filteredHints', filteredHints);

  useEffect(() => {
    if (!devMode && (hostname.includes('dev.') || hostname.includes('localhost'))) {
      setDevMode(true);
    }
  });

  useEffect(() => {
    dispatch(getAllHintsOld())
  }, []);

  useEffect(() => {
    if (checkDailyTip) {
      const checkDailyTipDay = moment(checkDailyTip).format('YYYY-MM-DD');
      const nowDateDay = moment(new Date()).format('YYYY-MM-DD');
      if (nowDateDay.toString() === checkDailyTipDay.toString()) {
        setDailyTipCheck(true);
      } else {
        setDailyTipCheck(false);
      }
    } else {
      setDailyTipCheck(false);
    }
  }, []);

  useEffect(() => {
    if (size(filteredHints) > 0) {
      const randomCnt = Math.floor(Math.random() * size(filteredHints));
      setRandomCount(randomCnt);
    }
  }, [allOldHints])

  const toggleUserShowHint = () => {
    setUserShowHint(!userShowHint);
  }

  const changeShowHint = () => {
    dispatch(toggleShowHint(userShowHint))
    setOpenHintModal(!openHintModal)
  }

  const selectedRandomHint = filteredHints[randomCount];
  return (
    <Modal container={'div > div'} isOpen={!dailyTipCheck && !showHint && filteredHints && size(filteredHints) > 0 && openHintModal && /*size(*/selectedRandomHint/*) > 0*/} toggle={changeShowHint} size="lg" className={`old-modal root-font-${fontMode}`}>
      <ModalHeader
        toggle={changeShowHint}
        close={
          <Media src={closeImg} className="notification-header-close" alt="X" onClick={changeShowHint} />
        }
      >
        {t('common.dailyTip')}
      </ModalHeader>
      <ModalBody>
        <div className='my-hints-old'>
          {
            size(filteredHints) > 0 && filteredHints[randomCount] && (
              <div className="my-hints-old-item">
                <div className="my-hints-old-item-title mb-2" >
                  {
                    i18n.language === constants.sendLanguage.en ?
                      selectedRandomHint.titleEN
                      : i18n.language === constants.sendLanguage.lv ?
                        selectedRandomHint.titleLV
                        : i18n.language === constants.sendLanguage.ru ?
                          selectedRandomHint.titleRU
                          : selectedRandomHint.titleLV
                  }
                </div>
                <div className="my-hints-old-item-img" >
                  <Media
                    className="slide-img"
                    src={
                      i18n.language === constants.sendLanguage.en ?
                        selectedRandomHint.enImageUrl
                        : i18n.language === constants.sendLanguage.lv ?
                          selectedRandomHint.lvImageUrl
                          : i18n.language === constants.sendLanguage.ru ?
                            selectedRandomHint.ruImageUrl
                            : selectedRandomHint.enImageUrl
                    }
                    alt=""
                  />
                </div>
                <div className="my-hints-old-item-content mt-4 mb-2">
                  {
                    i18n.language === constants.sendLanguage.en ?
                      selectedRandomHint.descriptionEN
                      : i18n.language === constants.sendLanguage.lv ?
                        selectedRandomHint.descriptionLV
                        : i18n.language === constants.sendLanguage.ru ?
                          selectedRandomHint.descriptionRU
                          : selectedRandomHint.descriptionLV
                  }
                </div>
              </div>
            )
          }
          <div className='d-flex flex-center align-items-center mt-2 my-hints-old-item-check-show'>
            <div className='d-flex align-items-center'>
              <Input type="checkbox" onClick={toggleUserShowHint} checked={userShowHint} readOnly />
              <span className="checkmark" />
            </div>
            <div className="event-type-text mt-1">
              {t('common.dailyTipShowOption')}
              {/* Vairs nerādīt dienas padomus (aktivizējams profila iestatījumos) */}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          className="contact-support-modal-footer-button"
          color="link"
          onClick={changeShowHint}
        >
          {t('common.ok')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Hints;
