import { omit } from 'lodash';
import Store from '../store/member';

import {
  SCREEN_MODE,
} from '../constants/constants';

export const initialState = Store;

export default function userReducer(state = initialState, action) {
  switch (action.type) {
  /*  case 'USER_LOGIN': {
      if (action.data) {
        return {
          ...state,
          uid: action.data.uid,
          email: action.data.email,
          emailVerified: action.data.emailVerified,
        };
      }
      return {};
    } */
    case 'USER_LOGGED_OUT': {
      return initialState;
    }

    case 'USER_DETAILS_UPDATE': {
      if (action.data) {
        return {
          ...state,
          name: action.data.name,
          blocked: action.data.blocked,
          firstName: action.data.firstName,
          lastName: action.data.lastName,
          gamesPlayed: action.data.gamesPlayed,
          level: action.data.level,
          balance: action.data.balance,
          photo: action.data.photo,
          totalPoints: action.data.totalPoints,
          gamesWon: action.data.gamesWon,
          role: action.data.role,
          firstTimeModal: action.data.firstTimeModal,
          newVersion: action.data.newVersion,
          rating: action.data.rating || 1600,
          minMobileVersion: action.data.minMobileVersion,
        };
      }
      return state;
    }

    case 'REMOVE_ACTIVE_ROOM': {
      return {
        ...state,
        activeRoom: null,
      };
    }

    case 'SET_BONUS_MODAL': {
      // console.log('SET_BONUS_MODAL', action);
      if (action.modalType === 'chest') {
        return {
          ...state,
          showChestBonusModal: action.data,
        };
      } if (action.modalType === 'advent') {
        return {
          ...state,
          showAdventBonusModal: action.data,
        };
      }
      return {
        state,
      };
    }

    case 'USER_DATA': {
      console.log('USER_DATA', action.data, state);
      // console.log('loggedInUserDataFetched set', action.data.uid, state.uid);

      // if (action.data && action.data.uid !== state.uid) {
      //   console.log('USER_DATA changed', action.data.uid, state.uid);
      // }

      // debugger;
      if (action.data) {
        const newState = {
        //  ...state,
          ...action.data,
          allBlogs: state.allBlogs,
          loggedInUserDataFetched: true,
          position: state.position,
          claimedAchievements: state.claimedAchievements || {},
          friends: state.friends || null,
          fbFriends: state.fbFriends || null,
          userAchievements: state.userAchievements || {},
          ignoredUsers: state.ignoredUsers || null,
          ignoredByUsers: state.ignoredByUsers || null,
          giftsHistory: state.giftsHistory || {},
          gamesHistory: state.gamesHistory || [],
          gamesHistoryTotal: state.gamesHistoryTotal || {},
          balanceHistory: state.balanceHistory || [],
          pointsHistory: state.pointsHistory || [],
          supportChat: state.supportChat || {},
          supportChatStatus: state.supportChatStatus || {},
          todayHolidays: state.todayHolidays || {},
          claimedRewards: state.claimedRewards || {},
          claimedRewardsFetched: state.claimedRewardsFetched || false,
          showAdventBonusModal: state.showAdventBonusModal || false,
          showChestBonusModal: state.showChestBonusModal || false,
          //  screenMode: state.screenMode || null,
          screenMode: action.data.screenMode || state.screenMode || SCREEN_MODE.normal,
          balance: action.data.bal,
          level: action.data.lvl,
          levelupGameLimit: action.data.lvlupLimit,
          gamesPlayed: action.data.gPlayed,
          gamesWon: action.data.gWon,
          totalPoints: action.data.totalPnts,
          nextBonusSpin: action.data.nxtSpin,
          lastBonusSpin: action.data.lastSpin,
          tutorialShown: action.data.tutorialShown || false,
          offset: state.offset,
          rating: action.data.rating || 1600,
          activeGifts: state.activeGifts || {},
          userTournamentsHistory: state.userTournamentsHistory || {},
          blocked: action.data.blocked,
          refferalToken: state.refferalToken || null,
          designPlatforms: state.designPlatforms || {},
          betterPos: state.betterPos || null,
          betterGPlayed: state.betterGPlayed || null,
          betterGWon: state.betterGWon || null,
          betterTotalPnts: state.betterTotalPnts || null,
          betterBal: state.betterBal || null,
          surveys: state.surveys || [],
          feedbacks: state.feedbacks || [],
          adminReturnToken: state.adminReturnToken || null,
          reportedPlayers: state.reportedPlayers || {},
          // adsHidden: state.adsHidden || null,
          // newVersion: state.newVersion || null,
          // newVersion: null,
        //  position: state.position,
        };

        return newState;
      }
      //return state;
    }

    case 'SET_REFFERAL_TOKEN': {
      // console.log('SET_REFFERAL_TOKEN', action.data);

      if (action.data) {
        return {
          ...state,
          refferalToken: action.data,
        };
      }
      return state;
    }

    case 'REMOVE_REFFERAL_TOKEN': {
      // console.log('REMOVE_REFFERAL_TOKEN');

      if (action.data) {
        return {
          ...state,
          refferalToken: null,
        };
      }
      return state;
    }

    case 'USER_LEADERBOARD_POSITION_CHANGE': {
      if (action.data) {
        return {
          ...state,
          position: action.data,
        };
      }
      return state;
    }

    case 'USER_DATA_CHANGE': {
      if (action.data) {
        // console.log('USER_DATA_CHANGE', action.data);

        const { key, userData } = action.data;

        //  if (key === 'joinedRooms' || key === 'activeRoom' || key === 'bal') {
        //    console.log(`${key} change`);
        //    console.log(userData);
        //  }

        let dataKey = key;

        if (key === 'bal') {
          dataKey = 'balance';
        } else if (key === 'lvl') {
          dataKey = 'level';
        } else if (key === 'pos') {
        //  dataKey = 'position';
        } else if (key === 'lvlupLimit') {
          dataKey = 'levelupGameLimit';
        } else if (key === 'gPlayed') {
          dataKey = 'gamesPlayed';
        } else if (key === 'gWon') {
          dataKey = 'gamesWon';
        } else if (key === 'totalPnts') {
          dataKey = 'totalPoints';
        } else if (key === 'nxtSpin') {
          dataKey = 'nextBonusSpin';
        } else if (key === 'lastSpin') {
          dataKey = 'lastBonusSpin';
        }

        return {
          ...state,
          [dataKey]: userData,
        };
      }
      return state;
    }

    case 'USER_DATA_ADDED': {
      if (action.data) {
        // console.log('USER_DATA_ADDED', action.data);

        const { key, userData } = action.data;

        let dataKey = key;

        if (key === 'bal') {
          dataKey = 'balance';
        } else if (key === 'lvl') {
          dataKey = 'level';
        } else if (key === 'lvlupLimit') {
          dataKey = 'levelupGameLimit';
        } else if (key === 'gPlayed') {
          dataKey = 'gamesPlayed';
        } else if (key === 'gWon') {
          dataKey = 'gamesWon';
        } else if (key === 'totalPnts') {
          dataKey = 'totalPoints';
        } else if (key === 'nxtSpin') {
          dataKey = 'nextBonusSpin';
        } else if (key === 'lastSpin') {
          dataKey = 'lastBonusSpin';
        }

        return {
          ...state,
          [dataKey]: userData,
        };
      }
      return state;
    }

    case 'USER_DATA_REMOVED': {
      if (action.data) {
        // console.log('USER_DATA_REMOVED', action.data);

        const { key } = action.data;

        return {
          ...state,
          [key]: null,
        };
      }
      return state;
    }

    case 'MOBILE_VERSION': {
      return {
        ...state,
        minMobileVersion: action.data,
      };
    }

    case 'TODAY_HOLIDAYS': {
      return {
        ...state,
        todayHolidays: action.data,
      };
    }

    case 'CHECK_NEW_VERSION': {
      return {
        ...state,
        newVersion: action.data,
      };
    }

    case 'USER_DATA_TOURNAMENT_HISTORY': {
      return {
        ...state,
        userTournamentsHistory: action.data,
      };
    }

    case 'CLAIMED_REWARDS': {
      return {
        ...state,
        claimedRewards: action.data,
        claimedRewardsFetched: true,
      };
    }

    case 'SET_USER_ACTIVE_ROOM': {
      return {
        ...state,
        activeRoom: action.data,
      };
    }

    case 'SET_USER_JOINED_ROOM': {
      return {
        ...state,
      //  joinedRooms: {[action.data.id]: action.data.position },
      };
    }

    case 'USER_LOGOUT': {
      if (action.data) {
        return {
          ...action.data,
          claimedAchievements: {},
          userAchievements: {},
          tutorialShown: false,
          firstTimeModal: true,
        };
      }
      return state;
    }

    case 'OFFSET': {
      if (action.data) {
        return {
          ...state,
          offset: action.data,
        };
      }
      return state;
    }

    case 'SUPPORT_CHAT': {
      if (action.data) {
        const messages = {};
        Object.keys(action.data).map((key) => {
          messages[key] = { ...action.data[key], messageId: key };
          return null;
        });

        return {
          ...state,
          supportChat: messages,
        };
      }
      return state;
    }

    case 'SUPPORT_CHAT_CHILD': {
      if (action.data) {
        return {
          ...state,
          supportChat: {
            ...state.supportChat,
            [action.key]: { ...action.data, messageId: action.key },
          },
        };
      }
      return state;
    }

    case 'SUPPORT_CHAT_MESSAGES_COUNT': {
      if (action.data) {
        return {
          ...state,
          supportChatMessagesCount: action.data,
        };
      }
      return state;
    }

    case 'SUPPORT_CHAT_STATUS': {
      return {
        ...state,
        supportChatStatus: action.data,
      };
    }

    case 'GAMES_HISTORY': {
      let total = {};
      const gamesHistory = [];

      if (action.data) {
        Object.keys(action.data).map((key) => {
          const data = action.data[key] || {};

          gamesHistory.push({ date: key, ...data });

          total = {
            ...total,
            wins: ((total.wins) ? total.wins : 0) + (data.wins ? data.wins : 0),
            largeWins: ((total.largeWins) ? total.largeWins : 0) + (data.largeWins ? data.largeWins : 0),
            smallWins: ((total.smallWins) ? total.smallWins : 0) + (data.smallWins ? data.smallWins : 0),
            zoleWins: ((total.zoleWins) ? total.zoleWins : 0) + (data.zoleWins ? data.zoleWins : 0),
            loses: ((total.loses) ? total.loses : 0) + (data.loses ? data.loses : 0),
            largeLoses: ((total.largeLoses) ? total.largeLoses : 0) + (data.largeLoses ? data.largeLoses : 0),
            smallLoses: ((total.smallLoses) ? total.smallLoses : 0) + (data.smallLoses ? data.smallLoses : 0),
            zoleLoses: ((total.zoleLoses) ? total.zoleLoses : 0) + (data.zoleLoses ? data.zoleLoses : 0),
            galdinsLoses: ((total.galdinsLoses) ? total.galdinsLoses : 0) + (data.galdinsLoses ? data.galdinsLoses : 0),
            galdinsWins: ((total.galdinsWins) ? total.galdinsWins : 0) + (data.galdinsWins ? data.galdinsWins : 0),
            roomsPlayed: ((total.roomsPlayed) ? total.roomsPlayed : 0) + (data.roomsPlayed ? data.roomsPlayed : 0),
            //  roundsPlayed: ((total.roundsPlayed) ? total.roundsPlayed : 0) + (data.roundsPlayed ? data.roundsPlayed : 0),
            roundsPlayed: ((total.roundsPlayed) ? total.roundsPlayed : 0) + (data.wins ? data.wins : 0) + (data.loses ? data.loses : 0),
            balChange: ((total.balChange) ? total.balChange : 0) + (data.balChange ? data.balChange : 0),
            totalMoney: ((total.totalMoney) ? total.totalMoney : 0) + (data.totalMoney ? data.totalMoney : (data.balChange ? data.balChange : 0)),
            pointsChange: ((total.pointsChange) ? total.pointsChange : 0) + (data.pointsChange ? data.pointsChange : 0),
          };

          // { rowData.totalMoney ? rowData.totalMoney : (rowData.balChange ? rowData.balChange : 0) }

          return null;
        });
      }

      gamesHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

      return {
        ...state,
        //  gamesHistory: gamesHistory,
        gamesHistory: [total, ...gamesHistory],
        gamesHistoryTotal: total,
      };
    }

    case 'DAILY_BONUS_HISTORY': {
      const array = [];

      Object.keys(action.data).map((key) => {
        if (action.data[key].type === 'dailyBonus') {
          array.push({ ...action.data[key], key });
          return null;
        }
      });

      array.sort((a, b) => b.time - a.time);

      return {
        ...state,
        dailyBonusHistory: array,
      };
    }

    case 'BAL_HISTORY': {
      const array = [];

      Object.keys(action.data).map((key) => {
        array.push({ ...action.data[key], key });
        return null;
      });

      array.sort((a, b) => b.time - a.time);

      return {
        ...state,
        balanceHistory: array,
      };
    }

    case 'TRANSACTION_HISTORY': {
      const array = [];

      Object.keys(action.data).map((key) => {
        array.push({ ...action.data[key], key });
        return null;
      });

      array.sort((a, b) => (b.dateInitiated - a.dateInitiated));

      return {
        ...state,
        transactionHistory: array,
      };
    }

    case 'POINTS_HISTORY': {
      const array = [];

      Object.keys(action.data).map((key) => {
        array.push({ ...action.data[key], key });
        return null;
      });

      array.sort((a, b) => b.time - a.time);

      return {
        ...state,
        pointsHistory: array,
      };
    }

    case 'DELETE_GIFT_FROM_HISTORY':
      return {
        ...state,
        giftsHistory: omit(state.giftsHistory, action.payload.giftId),
      };
    case 'GIFTS_HISTORY': {
      return {
        ...state,
        giftsHistory: action.data,
      };
    }

    case 'ACTIVE_GIFTS_REPLACE': {
      return {
        ...state,
        activeGifts: action.data,
      };
    }

    case 'USER_IGNORED': {
      return {
        ...state,
        ignoredUsers: {
          ...state.ignoredUsers,
          [action.data]: true,
        },
      };
    }

    case 'USER_UNIGNORED': {
      const newIgnoredUsers = { ...state.ignoredUsers };

      delete newIgnoredUsers[action.data];

      return {
        ...state,
        ignoredUsers: {
          ...newIgnoredUsers,
        },
      };
    }

    case 'FB_FRIENDS': {
      return {
        ...state,
        fbFriends: action.data,
      };
    }

    case 'FB_FRIEND': {
      const { fbUser, id } = action.data;

      return {
        ...state,
        fbFriends: {
          ...state.fbFriends,
          [id]: fbUser,
        },
      };
    }

    case 'LOADING_FRIEND_LOGS': {
      return {
        ...state,
        friends: action.data,
        isLoading: action.isLoading,
      };
    }

    case 'FRIENDS': {
      return {
        ...state,
        friends: action.data,
        isLoading: action.isLoading,
      };
    }

    case 'IGNORED_PLAYERS': {
      return {
        ...state,
        ignoredUsers: action.data,
      };
    }

    case 'IGNORED_BY_PLAYERS': {
      return {
        ...state,
        ignoredByUsers: action.data,
      };
    }

    case 'WEEKLY_STATISTICS': {
      return {
        ...state,
        weeklyStatistics: action.data,
      };
    }

    case 'USER_ACHIEVEMENTS': {
      return {
        ...state,
        userAchievements: action.data,
      };
    }

    case 'CLAIMED_ACHIEVEMENTS': {
      return {
        ...state,
        claimedAchievements: action.data,
      };
    }

    case 'USER_CONNECTED_STATUS': {
      return {
        ...state,
        userConnected: action.data,
      };
    }

    case 'USER_RESET': {
      return {};
    }

    case 'SMART_LOOK_STATUS': {
      return {
        ...state,
        smartLookStatus: action.data,
      };
    }

    case 'CHECK_FEEDBACK_REPLACE': {
      return {
        ...state,
        userFeedback: action.data,
      };
    }

    case 'ADD_FEEDBACK_REPLACE': {
      return {
        ...state,
        addFeedback: action.data,
      };
    }

    case 'EMIL_VERIFY_REPLACE': {
      return {
        ...state,
      };
    }

    case 'GET_LATEST_VERSION_REPLACE': {
      return {
        ...state,
        latestVersion: action.data,
      };
    }

    case 'GET_ALL_VERSION_REPLACE': {
      return {
        ...state,
        allVersions: action.data,
      };
    }

    case 'GET_ALL_BLOGS_REPLACE': {
      return {
        ...state,
        allBlogs: action.data,
      };
    }

    case 'MY_INFO_POS_PERCENTAGE': {
      return {
        ...state,
        betterPos: action.data,
      };
    }
    case 'MY_INFO_GPLAYED_PERCENTAGE': {
      return {
        ...state,
        betterGPlayed: action.data,
      };
    }
    case 'MY_INFO_GWON_PERCENTAGE': {
      return {
        ...state,
        betterGWon: action.data,
      };
    }
    case 'MY_INFO_TOTALPNTS_PERCENTAGE': {
      return {
        ...state,
        betterTotalPnts: action.data,
      };
    }
    case 'MY_INFO_BAL_PERCENTAGE': {
      return {
        ...state,
        betterBal: action.data,
      };
    }
    case 'TODAY_ROOM_LOGS': {
      return {
        ...state,
        gameLogs: action.data,
        isloading: action.isLoading,
      };
    }

    case 'SUPPORT_MESSAGE_SUCCESS': {
      return {
        ...state,
        message: action.data,
        supportMessageError: null,
      };
    }

    case 'SUPPORT_MESSAGE_ERROR': {
      return {
        ...state,
        supportMessageError: action.data,
      };
    }

    case 'SET_USER_FONT_MODE': {
      return {
        ...state,
        fontMode: action.data,
      };
    }

    case 'GET_USER_FONT_MODE': {
      return {
        ...state,
        fontMode: action.data,
      };
    }

    case 'GET_USER_NEW_DESIGN': {
      // console.log('GET_USER_NEW_DESIGN', action.data);
      return {
        ...state,
        newDesign: action.data,
      };
    }

    case 'SET_USER_NEW_DESIGN': {
      // console.log('SET_USER_NEW_DESIGN', action.data);
      return {
        ...state,
        newDesign: action.data,
      };
    }

    case 'UPDATE_SCREEN_MODE': {
      return {
        ...state,
        screenMode: action.data,
      };
    }

    case 'UPDATE_WEEKLY_DATA': {
      return {
        ...state,
        weeklyDataOpen: action.data,
      };
    }

    case 'GET_SCREEN_MODE': {
      return {
        ...state,
        screenMode: action.data,
      };
    }

    case 'TOGGLE_AUTO_PLAY': {
      return {
        ...state,
        disabledAutoPlay: action.data,
      };
    }

    case 'TOGGLE_SHOW_HINT': {
      return {
        ...state,
        showHint: action.data,
      };
    }

    case 'REPLACE_AUTO_PLAY': {
      return {
        ...state,
        disabledAutoPlay: action.data,
      };
    }

    case 'REPLACE_SHOW_HINT': {
      return {
        ...state,
        showHint: action.data,
      };
    }

    case 'REPLACE_SHOW_IGNORED_ROOMS': {
      return {
        ...state,
        showIgnoredRooms: action.data,
      };
    }

    case 'GET_REPORTED_PLAYERS_REPLACE': {
      return {
        ...state,
        reportedPlayers: action.data,
      };
    }

    case 'GET_DESIGN_PLATFORM': {
      return {
        ...state,
        designPlatforms: action.data,
      };
    }

    case 'GET_FEEDBACKS': {
      return {
        ...state,
        feedbacks: action.data || [],
      };
    }

    case 'GET_SURVEYS': {
      return {
        ...state,
        surveys: action.data,
      };
    }

    case 'USER_SUBMIT_SURVEY': {
      return {
        ...state,
        surveyResult: action.data,
      };
    }

    case 'CHECK_USER_SURVEY': {
      return {
        ...state,
        userIsSurvey: action.data || false,
      };
    }

    case 'GET_NEW_HELP_REPLACE': {
      return {
        ...state,
        newHelps: action.data || {},
      };
    }

    default:
      return state;
  }
}
