import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { filter } from 'lodash';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';

import Moment from 'react-moment';
import Select  from 'react-select';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

// import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
import * as constants from '../../../constants/constants';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { ENV_MODE } from '../../../constants/constants';

class AllBans extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    allPayments: PropTypes.shape({}),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    paymentsCount: PropTypes.number,
    completedPaymentsCount: PropTypes.number,
    initiatedPaymentsCount: PropTypes.number,
    fetchPaymentsRange: PropTypes.func.isRequired,
    fetchCompletedPaymentsRange: PropTypes.func.isRequired,
    fetchInitiatedPaymentsRange: PropTypes.func.isRequired,
    fetchFilteredTransactions: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
    allPayments: {},
    paymentsCount: null,
    completedPaymentsCount: null,
    initiatedPaymentsCount: null,
  }

  constructor(props) {
    let date = new Date();

    console.log('date 1', date);

    date = date.setDate(date.getDate() - 3);

    console.log('date 2', date);

    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      show: 'initiatedPayments',
      isFiltered: true,
      selectedOption: null,
      filterDateFrom: new Date(date),
      filterDateTo: new Date(),
      filterProvider: 'all',
      filterUUID: '',
      selectedEnvOption: ENV_MODE[0],
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { fetchPaymentsRange, paymentsCount, fetchFilteredTransactions } = this.props;
    const { selectedEnvOption, filterDateFrom, filterDateTo, show } = this.state;

    const start = paymentsCount - 49;
    const end = paymentsCount;
    const devMode = selectedEnvOption.value === 'dev' ? true : null;

    // fetchPaymentsRange(start, end, devMode);

    fetchFilteredTransactions('all', null, filterDateFrom, filterDateTo, show, '', devMode);
  }


  handleClick(e, index) {
    e.preventDefault();
    const { fetchPaymentsRange, fetchCompletedPaymentsRange, paymentsCount, completedPaymentsCount, fetchInitiatedPaymentsRange, initiatedPaymentsCount } = this.props;
    const { show, isFiltered, selectedEnvOption } = this.state;
    this.setState({
      currentPage: index,
    });

    if (show === 'completed') {
      const start = completedPaymentsCount - (50 * index) - 49;
      const end = completedPaymentsCount - (50 * index);

      fetchCompletedPaymentsRange(start, end);

      this.setState({
        currentPage: index,
      });
    } else if (show === 'initiated') {
      const start = initiatedPaymentsCount - (50 * index) - 49;
      const end = initiatedPaymentsCount - (50 * index);

      fetchInitiatedPaymentsRange(start, end);

      this.setState({
        currentPage: index,
      });
    } else {
      if (!isFiltered) {
        const start = paymentsCount - (50 * index) - 49;
        const end = paymentsCount - (50 * index);

        console.log('fetchPaymentsRange', start, end);

        fetchPaymentsRange(start, end);

        this.setState({
          currentPage: index,
        });
      } else {
        this.setState({
          currentPage: index,
        });
      }
    }
  }

  changeProvider = (e) => {
    this.setState({ filterProvider: e.target.value});
  }

  changeShow = (e) => {
    console.log('changeShow');
    console.log(e.target.value);
    this.setState({
      currentPage: 0,
      show: e.target.value,
      isFiltered: true,
    });
  }

  changeFilterDateFrom = (event) => {
    console.log('event', event);
    this.setState({ filterDateFrom: event });
  }

  changeFilterDateTo = (event) => {
    this.setState({ filterDateTo: event });
  }

  changePurchaseOption = (event) => {
    this.setState({
      selectedOption: event,
      isFiltered: true,
    });
  }

  changefilterUUID = (event) => {
    this.setState({ filterUUID : event.target.value });
  }

  filterTransactions = () => {
    const { fetchFilteredTransactions } = this.props;
    const {
      filterDateFrom, filterDateTo, filterProvider, selectedOption, show, filterUUID, selectedEnvOption
    } = this.state;
    const devMode = selectedEnvOption.value === 'dev' ? true : null;

    fetchFilteredTransactions(filterProvider, selectedOption, filterDateFrom, filterDateTo, show, filterUUID, devMode);
    this.setState({ isFiltered: true });
  }

  changeEnvOption = (selectedEnvOption) => {
    this.setState({
      selectedEnvOption,
    })
  }

  table() {
    const {
      paymentsCount, allPayments, removedPaymentsCount,
    } = this.props;
    const {
      currentPage,
      pageSize,
      isFiltered,
    } = this.state;

    let pagesCount = Math.ceil((paymentsCount) / pageSize);

    if (isFiltered) {
      pagesCount = Math.ceil(allPayments.length / pageSize);
    }

    console.log('pagesCount', pagesCount);


    if (!isFiltered) {
      return (
        <Fragment>
          {allPayments && allPayments.length > 0 && allPayments
            .map((payment, index) => (
              <Fragment key={payment.id}>
                {console.log('payment test', payment)}
                <tr key={payment.id} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                  <td className="allUsers-table-col">
                    { payment.id }
                  </td>
                  <td className="allUsers-table-col">
                    <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                      {payment.date}
                    </Moment>
                  </td>
                  <td className="allUsers-table-col">
                    { payment.userUid }
                  </td>
                  <td className="allUsers-table-col">
                    {payment.productNr ? filter(constants.SHOP_PRODUCT_TYPES, ['id', payment.productNr])[0].productNr : null}
                  {/*  {payment.productNr === 1 ? 'Purchase - 250Z for 0.85 EUR' : null}
                    {payment.productNr === 2 ? 'Purchase - 500Z for 1.41 EUR' : null}
                    {payment.productNr === 3 ? 'Purchase - 1000Z for 2.85 EUR' : null}
                    {payment.productNr === 4 ? 'Purchase - 4000Z for 4.27 EUR' : null}
                    {payment.productNr === 5 ? 'Purchase - 15000Z for 14.23 EUR' : null}
                    {payment.productNr === 6 ? 'Purchase - Reset balance' : null}
                    {payment.productNr === 7 ? 'Purchase - Reset points' : null}
                    {payment.productNr === 8 ? 'Purchase - Reset profile' : null}
                    {payment.productNr === 9 ? 'Payment for unbanning account' : null} */}
                  </td>
                  <td className="allUsers-table-col">
                    {payment.discountPrice || '-'}
                  </td>
                  <td className="allUsers-table-col">
                    {payment.status}
                  </td>
                  <td className="allUsers-table-col">
                    { payment.provider }
                  </td>
                </tr>
              </Fragment>
            ))}
          {paymentsCount > pageSize && (
            <div className="pagination-wrapper">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage === 0}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                    {`‹‹‹`}
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink
                    style={{ color: '#000' }}
                    onClick={e => this.handleClick(e, currentPage - 1)}
                    previous
                    href="#"
                  />
                </PaginationItem>

                {[...Array(pagesCount || 1)].map((page, i) => {
                  if (i > currentPage - 3 && i < currentPage + 3) {
                    return (
                      <PaginationItem active={i === currentPage} key={page}>
                        <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  }

                  return null;
                })}

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink
                    style={{ color: '#000' }}
                    onClick={e => this.handleClick(e, currentPage + 1)}
                    next
                    href="#"
                  />
                </PaginationItem>
                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {allPayments && allPayments.length > 0 && allPayments.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
            .map((payment, index) => (
              <Fragment key={payment.id}>
                {console.log('payment test', payment)}
                <tr key={payment.id} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                  <td className="allUsers-table-col">
                    { payment.id }
                  </td>
                  <td className="allUsers-table-col">
                    <Moment format="DD-MM-YYYY, HH:mm" locale="lv">
                      {payment.date}
                    </Moment>
                  </td>
                  <td className="allUsers-table-col">
                    { payment.userUid }
                  </td>
                  <td className="allUsers-table-col">
                    {
                      filter(constants.SHOP_PRODUCT_TYPES, ['id', payment.productNr])[0].productNr
                    }
                  </td>
                  <td className="allUsers-table-col">
                    {payment.discountPrice || '-'}
                  </td>
                  <td className="allUsers-table-col">
                    { payment.status }
                  </td>
                  <td className="allUsers-table-col">
                    { payment.provider }
                  </td>
                </tr>
              </Fragment>
            ))}
          {paymentsCount > pageSize && (
            <div className="pagination-wrapper">
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage === 0}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                    {`‹‹‹`}
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink
                    style={{ color: '#000' }}
                    onClick={e => this.handleClick(e, currentPage - 1)}
                    previous
                    href="#"
                  />
                </PaginationItem>

                {[...Array(pagesCount)].map((page, i) => {
                  if (i > currentPage - 3 && i < currentPage + 3) {
                    return (
                      <PaginationItem active={i === currentPage} key={page}>
                        <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  }

                  return null;
                })}

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink
                    style={{ color: '#000' }}
                    onClick={e => this.handleClick(e, currentPage + 1)}
                    next
                    href="#"
                  />
                </PaginationItem>

                <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          )}
        </Fragment>
      );
    }
  }

  render() {
    const { t } = this.props;
    const { show, filterProvider, selectedOption, filterDateFrom, filterDateTo, filterUUID, selectedEnvOption } = this.state;
    const options = [
      { value: '1', label: 'Purchase - 250Z for 0.85 EUR' },
      { value: '2', label: 'Purchase - 500Z for 1.41 EUR' },
      { value: '3', label: 'Purchase - 1000Z for 2.85 EUR' },
      { value: '4', label: 'Purchase - 4000Z for 4.27 EUR' },
      { value: '5', label: 'Purchase - 15000Z for 14.23 EUR' },
      { value: '6', label: 'Purchase - Reset balance' },
      { value: '7', label: 'Purchase - Reset points' },
      { value: '8', label: 'Purchase - Reset profile' },
      { value: '9', label: 'Payment for unbanning account' },
      { value: '10', label: 'Purchase - Remove ads for 1 month' },
      { value: '11', label: 'Purchase - Remove ads for 1 year' },
    ];
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'gray',
        padding: 10,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
      }
    }

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-all-transactions">
          <PopoverBody className="popover-body">
            A list of all initiated and completed transactions in the game.
          </PopoverBody>
        </UncontrolledPopover>
        <div className="admin-page-content">
          <h2>
            {/* {t('transactions')} */}
            Transactions
            <Button className="admin-help-button" id="admin-help-button-all-transactions">
              ?
            </Button>
          </h2>
          <Row>
            <Col sm="1">
              <h5>
                {/* {t('state')} */}
                State
              </h5>
              <Input type="select" name="show" className="game-bet-select" value={show} onChange={this.changeShow}>
                <option style={{ backgroundColor: '#222' }} value="initiatedPayments">All States</option>
                <option style={{ backgroundColor: '#222' }} value="completedPayments">Completed</option>
                <option style={{ backgroundColor: '#222' }} value="incompletePayments">Initiated</option>
              </Input>
            </Col>
            <Col sm="1">
                <div>
                <h5>
                  {/* {t('platform')} */}
                  Platform
              </h5>
                <Input type="select" name="show" className="game-bet-select" value={filterProvider} onChange={this.changeProvider}>
                  <option style={{ backgroundColor: '#222' }} value="all">All Platforms</option>
                  <option style={{ backgroundColor: '#222' }} value="stripe">Web</option>
                  <option style={{ backgroundColor: '#222' }} value="every-pay">Every Pay</option>
                  <option style={{ backgroundColor: '#222' }} value="draugiem">Draugiem</option>
                  <option style={{ backgroundColor: '#222' }} value="facebook">Facebook</option>
                  <option style={{ backgroundColor: '#222' }} value="inbox">Inbox</option>
                  <option style={{ backgroundColor: '#222' }} value="mobile">Mobile</option>
                  {/* <option style={{ backgroundColor: '#222' }} value="android">Android</option>
                  <option style={{ backgroundColor: '#222' }} value="apple">IOS</option> */}
                </Input></div>
            </Col>
            <Col sm="5">
                <div>
                  <h5>
                    {/* {t('purchasedItem')} */}
                    Purchased Item
                </h5>
                <Select closeMenuOnSelect={false} options={options} isMulti={true} styles={customStyles} value={selectedOption}  onChange={this.changePurchaseOption}/>
                </div>
            </Col>
            <Col sm="2">
                 <div>
                 <h5>
                   {/* {t('from')} */}
                   From
               </h5>
                    <DateTimePicker
                      format="DD.MM.YYYY"
                      culture="lv"
                      time={false}
                      onChange={this.changeFilterDateFrom}
                      value={filterDateFrom}
                      defaultValue={filterDateFrom}
                    />
                </div>
            </Col>
            <Col sm="2">
              <div>
                <h5>
                    {/* {t('to')} */}
                    To
                </h5>
                <DateTimePicker
                  format="DD.MM.YYYY"
                  culture="lv"
                  time={false}
                  onChange={this.changeFilterDateTo}
                  value={filterDateTo}
                  defaultValue={filterDateFrom}
                />
              </div>
            </Col>
            <Fragment>
              <Col sm="8">
                <div>
                  <h5>
                      {/* {t('playerId')} */}
                      Player Id
                  </h5>
                    <Input
                      type="string"
                      name="filterValue"
                      id="filterValue"
                      value={filterUUID}
                      onChange={this.changefilterUUID}
                    />
                  </div>
              </Col>
              <Col sm="3">
                <div>
                <h5 for="length">
                  Environment
                </h5>
                <Select closeMenuOnSelect={true} options={ENV_MODE} styles={customStyles} value={selectedEnvOption} onChange={this.changeEnvOption} />
              </div>
              </Col>
            </Fragment>
            <Button color="primary" onClick={this.filterTransactions}>
              {/* {t('filter')} */}
              Filter
            </Button>
          </Row>
          <table style={{ width: '100%', fontSize: 12, textAlign: 'center' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  Id
                </th>
                <th>
                  {/* {t('date')} */}
                  Date
                </th>
                <th>
                  {/* {t('playerId')} */}
                  Player Id
                </th>
                <th>
                  Product No
                </th>
                <th>
                  Discount price
                </th>
                <th>
                  Status
                </th>
                <th>
                  Provider
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>

        </div>
      </Fragment>
    );
  }
}

export default  withTranslation('admin')(AllBans);
