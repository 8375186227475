import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Label, Alert, Input, Button, FormGroup, Media } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ReactGA from 'react-ga';

import { Firebase, FirebaseRef } from '../../../../lib/firebase';

import ForgotPasswordComponent from './ForgotPasswordComponent';
import LoginFormComponent from './LoginFormComponent';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import RecoverEmail from './RecoverEmail';
import TermsOfUsePage from '../Menu/TermsOfUsePage';
import CustomModal from '../Components/Components/Modal';
import LanguageSelect from '../Components/Layout/LanguageSelect';
import { TextInput } from '../Components/Components/TextInput';
import * as EmailValidator from 'email-validator';

import { fbLoginPopup as checkFacebookLoginState, draugiemAuth, afterGoogleLogin, setDesign } from '../../../../actions/member';
import {
  sendLanguage,
  PROFILE_FIELDS,
  SCREEN_MODE,
  stages,
  FONT_MODE,
} from '../../../../constants/constants';

import { saveRefferalToken } from '../../../../actions/refferal';

import closeImg from '../../../../images/landing-view/close.svg';
import lightCloseImg from '../../../../images/redesign/light-mode/components/modal/close.png';
import mobileCloseImg from '../../../../images/redesign/components/modal/mobileClose.png';
import logoImg from '../../../../images/redesign/landing-view/zoleLogo.svg';
import fullscreen from '../../../../images/redesign/landing-view/fullscreen.svg';
import member from '../../../../store/member';
import modalCloseImg from '../../../../images/redesign/components/modal/close.png';
import closeModalImg from '../../../../images/icons/close_mobile.png';
import maxFullscreenImg from '../../../../images/redesign/top-row/minimized.svg';
import minFullscreenImg from '../../../../images/redesign/top-row/maximized.svg';
import lightMaxFullscreenImg from '../../../../images/redesign/light-mode/icons/minimized.svg';
import lightMinFullscreenImg from '../../../../images/redesign/light-mode/icons/maximized.png';

import { errorMessages } from '../../../../constants/messages';
import defaultFont from '../../../../images/redesign/top-row/defaultFont.webp';
import largeFont from '../../../../images/redesign/top-row/largeFont.webp';
import xLargeFont from '../../../../images/redesign/top-row/xLargeFont.webp';

const Login = ({
  error,
  success,
  getDraugiemAuthUrl,
  onFormSubmit,
  history,
  checkFacebookLogin,
  afterGoogleLoginFunction,
  draugiemAuthFunc,
  toggleFullscreen,
  onForgotPasswordFormSubmit,
  loadingSubmit,
  messageType,
  initialState,
  resetPasswordFlag,
  onSubmitResetPassword,
  onRegisterFormSubmit,
  isFullscreenEnabled,
  screenMode,
  recoverUser,
  toggleRecoverUser,
  //handleRecoverUserSubmit,
  onRecoverUserSubmit,
  fontMode,
  toggleFont,
  newDesign,
  setDesignFunc,
  saveRefToken,
}) => {
  const { i18n, t } = useTranslation('common');

  const [email, setEmail] = useState(member && member.email ? member.email : '');
  const [password, setPassword] = useState('');
  const [FB, setFB] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [stateSuccess, setStateSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [draugiemAuthUrl, setDraugiemAuthUrl] = useState(null);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [actionCode, setActionCode] = useState(null);
  const [lang, setLang] = useState(null);
  const [confirmPass, setConfirmPass] = useState('');
  const [toggleSignUpOpen, setToggleSignUpOpen] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [terms, setTerms] = useState('');
  const [termsOfUsePageOpen, setTermsOfUsePageOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [inError, setInError] = useState('');

  // FB stuff
  const initializeFacebookLogin = useCallback((event) => {
    // const { window } = event;
    setFB(window.FB);
  }, []);

  const getParameterByName = (paramName, url) => {
    let url2;
    if (!url) {
      url2 = window.location.href;
    } else {
      url2 = url;
    }

    const name2 = paramName.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name2}(=([^&#]*)|&|#|$)`);

    const results = regex.exec(url2);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  useEffect(() => {
    console.log('useEffect');
    if (window.FB) {
      setFB(window.FB);
    } else {
      document.addEventListener('FBObjectReady', initializeFacebookLogin);
    }

    const { search } = window.location;
    let paramActionCode;
    let paramLang;
    let paramEmail;
  //  let refferalToken;

    if (resetPasswordFlag) {
      try {
        const urlParams = new URLSearchParams(search);
        paramActionCode = urlParams.get('actionCode');
        paramEmail = urlParams.get('email');
        paramLang = urlParams.get('lang') || sendLanguage.lv;
      //  refferalToken = urlParams.get('refferalToken');
      } catch (err) {
        paramActionCode = getParameterByName('actionCode');
        paramLang = getParameterByName('lang') || sendLanguage.lv;
        paramEmail = getParameterByName('email');
      //  refferalToken = getParameterByName('refferalToken');
      }

    //  console.log('refferalToken', refferalToken);

      setActionCode(paramActionCode);
      setEmail(paramEmail);
      setLang(paramLang);

    //  saveRefToken(refferalToken);
    }

    return () => {
      document.removeEventListener('FBObjectReady', initializeFacebookLogin);
    };
  }, []);

  const facebookLoginHandler = (response, fromLoginForm) => {
    const language = i18n.language || sendLanguage.lv;
    setLoading(true);
    checkFacebookLogin(response, language).then((res) => {
      if (res.data) {
        if (res.data.uid) {
          setDesignFunc(newDesign);
          history.push('/');
          setDate(Date.now());
        } else {
          if (fromLoginForm) setStateSuccess(null); // error: 'notLoggedIn',
          setLoading(false);
        }
      } else {
        if (fromLoginForm) setStateSuccess(null); // error: 'notLoggedIn',
        setLoading(false);
      }
    }).catch((err) => {
      setLoading(false);
      setStateSuccess(null);
    });
  };

  const FBLogin = useCallback(() => {
    // e.preventDefault();

    setLoading(true);

    if (!FB || loading) return;

    FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        facebookLoginHandler(response, true);
      } else {
        FB.login((response2) => { facebookLoginHandler(response2, true); });
      }
    });
  }, []);

  const googleLogin = useCallback((e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);

    const provider = new Firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    const language = i18n.language || sendLanguage.lv;
    Firebase.auth().languageCode = language;

    Firebase.auth().signInWithPopup(provider).then((_result) => {
      if (_result.additionalUserInfo) {
        afterGoogleLoginFunction(_result.additionalUserInfo, language).catch((err) => {
          console.log(err);
        });
      }
      try {
        localStorage.setItem('justLoggedIn', 'true'); //x
      } catch (err) {
        console.log(err);
      }
      setDesignFunc(newDesign);
      history.push('/');
      setDate(Date.now());

    }).catch((error2) => {
      if (error2.code !== 'auth/popup-closed-by-user') {
        setStateError(error2);
        setStateSuccess(null);
      }
      setLoading(false);
    });
  }, []);

  // Draugiem stuff
  const draAuthHandler = useCallback((e) => {
    console.log("dra login handler");
    const language = i18n.language || sendLanguage.lv;
    window.removeEventListener('dr_auth_code', draAuthHandler);

    const code = (e.detail && e.detail.dr_auth_code) ? e.detail.dr_auth_code : null;

    if (!code) {
      setStateError('loginFailure');
      setStateSuccess(null);
      setLoading(false);
      return;
    }

    setLoading(true);

    draugiemAuthFunc(code, false, language).then(() => {
      if (Firebase.auth().currentUser) {
        try {
          localStorage.setItem('justLoggedIn', 'true'); //x
        } catch (err) {
          console.log(err);
        }
        setDesignFunc(newDesign);
        history.push('/');
        setDate(Date.now());
      } else {
        setStateError('loginFailure');
        setStateSuccess(null);
        setLoading(false);
      }
    }).catch((_error) => {
      setStateError('loginFailure');
      setStateSuccess(null);
      setLoading(false);
    });
  }, []);

  const draLogin = useCallback((e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(false);
    if (draugiemAuthUrl) {
      // const popup = window.open(draugiemAuthUrl, 'Draugiem.lv', 'width=200,height=200');
      // pop-up solution for iOS Safari
      let popup;
      setTimeout(() => {
        popup = window.open(draugiemAuthUrl, 'Draugiem.lv', 'width=200,height=200');
      });

      window.addEventListener('dr_auth_code', draAuthHandler, false);

      let leftDomain = false;
      const interval = setInterval(() => {
        try {
          if (popup.document && popup.document.domain === document.domain) {
            if (leftDomain && popup.document.readyState === 'complete') {
              clearInterval(interval);

              let authCodeText;
              try {
                const urlParams = new URLSearchParams(popup.document.location.search);
                authCodeText = urlParams.get('dr_auth_code');
              } catch (err) {
                authCodeText = getParameterByName('dr_auth_code');
              }

              const evt = document.createEvent('CustomEvent');
              evt.initCustomEvent('dr_auth_code', false, false, { dr_auth_code: authCodeText });

              window.dispatchEvent(evt);

              popup.close();
            } else {
              console.log('test 1');
              leftDomain = true;
            }
          } else {
            console.log('test 2');
            leftDomain = true;

            clearInterval(interval);
            setLoading(false);
          }
        } catch (err) {
          console.log('popup err', { err });
          if (popup.closed) {
            clearInterval(interval);
            setLoading(false);
            return;
          }
          leftDomain = true;
        }
      }, 1000);
    } else {
      const loc = window.location;
      const redirectUrl = `${loc.protocol}//${loc.host}/dra-redirect`;

      getDraugiemAuthUrl(redirectUrl).then((result) => {
        // const popup = window.open(result.data.url, 'Draugiem.lv', 'width=200,height=200');
        // pop-up solution for iOS Safari
        let popup;
        setTimeout(() => {
          popup = window.open(result.data.url, 'Draugiem.lv', 'width=200,height=200');
        });

        window.addEventListener('dr_auth_code', draAuthHandler, false);

        let leftDomain = false;
        const interval = setInterval(() => {
          try {
            if (popup.document && popup.document.domain === document.domain) {
              if (leftDomain && popup.document.readyState === 'complete') {
                clearInterval(interval);

                let authCodeText;
                try {
                  const urlParams = new URLSearchParams(popup.document.location.search);
                  authCodeText = urlParams.get('dr_auth_code');
                } catch (err) {
                  console.log(err);
                  authCodeText = getParameterByName('dr_auth_code');
                }

                const evt = document.createEvent('CustomEvent');
                evt.initCustomEvent('dr_auth_code', false, false, { dr_auth_code: authCodeText });

                window.dispatchEvent(evt);
                popup.close();
              } else {
                leftDomain = true;
              }
            } else {
              leftDomain = true;

              clearInterval(interval);

              setLoading(false);
            }
          } catch (e) {
            if (popup.closed) {
              clearInterval(interval);

              setLoading(false);
              return;
            }
            leftDomain = true;
          }
        }, 1000);
      }).catch((_error) => {
        setLoading(false);
      });
    }
  }, []);

  // Form stuff
  const handleChange = useCallback((e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === PROFILE_FIELDS.terms) setTerms(value);
  }, []);

  const handleInputChange = useCallback((value, type) => {
    if (type === PROFILE_FIELDS.email) setEmail(value);
    if (type === PROFILE_FIELDS.name) setName(value);
    if (type === PROFILE_FIELDS.surname) setSurname(value);
    if (type === PROFILE_FIELDS.password) setPassword(value);
    if (type === PROFILE_FIELDS.confirmPass) setConfirmPass(value);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const language = i18n.language || sendLanguage.lv;
    if (loading) return;

    if (!email) {
      setStateError(errorMessages.missingEmail);
      setStateSuccess(null);
      setLoading(false);
      return;
    }

    setLoading(true);

    if (!EmailValidator.validate(email)) {
      setStateError(errorMessages.wrongEmail);
      setStateSuccess(null);
      setLoading(false);
      return;
    }

    if (!password) {
      setStateError(errorMessages.missingPassword);
      setStateSuccess(null);
      setLoading(false);
      return;
    }

    onFormSubmit({ email, password }, language)
      .then(() => {
        try {
          localStorage.setItem('justLoggedIn', 'true'); //x
        } catch (err) {
          console.log(err);
        }
        setDesignFunc(true);
        history.push('/');
        setDate(Date.now());
        setLoading(false);
      })
      .catch((err) => {
        setStateError(err.code);
        setStateSuccess(null);
        setLoading(false);
      });
  };

  const handleSubmitForgotPassword = (e) => {
    e.preventDefault();
    const language = i18n.language || sendLanguage.lv;

    if (!email) {
      return setInError(errorMessages.missingEmail);
    }

    if (!EmailValidator.validate(email)) {
      console.log(email, 'email00000000');
      return setInError(errorMessages.wrongEmail);
    }
    const data = { email };
    return onForgotPasswordFormSubmit(data, language)
      .then(() => {
        setTimeout(() => history.push('/login'), 10000);
        setInError('');
      })
      .catch(() => {
        setInError('');
      });
  };

  const toggleForgotPassword = () => {
    console.log('toggleForgotPassword', forgotPasswordOpen);
    if (!forgotPasswordOpen) {
      ReactGA.event({
        category: 'Landing',
        action: 'Landing - Reset Password',
      });
    }

    setForgotPasswordOpen(prevForgotPasswordOpen => !prevForgotPasswordOpen);
    setEmail('');
    setInError('');
    initialState();
  };

  const submitResetPassword = () => {
    setDate(Date.now());
    onSubmitResetPassword(email, lang, actionCode, password, confirmPass)
      .then(() => initialState())
      .catch(() => { });
  };

  const toggleSignUp = () => {
    setEmail('');
    setName('');
    setSurname('');
    setPassword('');
    setConfirmPass('');
    setToggleSignUpOpen(prevToggleSignUpOpen => !prevToggleSignUpOpen);
    setStateSuccess('');
    setStateError('');
    initialState();
  };

  const toggleTermsOfUse = () => {
    setTermsOfUsePageOpen(prevTermsOfUsePageOpen => !prevTermsOfUsePageOpen);
  };

  const handleSubmitRegister = (e) => {
    e.preventDefault();
    if (terms) {
      onRegisterFormSubmit({ email, password, password2: confirmPass, firstName: name, lastName: surname }, i18n.language || sendLanguage.lv);
    }
  };

  const goToLanding = () => {
    console.log('goToLanding', history);
    // history.push('/landing');

    console.log('history.push to leaveRoom 2222', { history: history.location });
    history.push('/landing');

    console.log('history.push to leaveRoom 2 2222', { history: history.location });
  };

  const screenModeImg = useCallback(() => {
    if (screenMode === SCREEN_MODE.light && isFullscreenEnabled) return lightMinFullscreenImg;
    if (screenMode === SCREEN_MODE.light && !isFullscreenEnabled) return lightMaxFullscreenImg;
    if (screenMode !== SCREEN_MODE.light && isFullscreenEnabled) return minFullscreenImg;
    if (screenMode !== SCREEN_MODE.light && !isFullscreenEnabled) return maxFullscreenImg;
  });

  const toggleFontFunc = () => {
    console.log(fontMode, 'login font mode');
    if (fontMode === FONT_MODE.normal) {
      toggleFont(FONT_MODE.large);
    } else if (fontMode === FONT_MODE.large) {
      toggleFont(FONT_MODE.xLarge);
    } else {
      toggleFont(FONT_MODE.normal);
    }
  };

  const passFacebookAuth = () => {
    try {
      localStorage.setItem('justLoggedIn', 'true'); //x
    } catch (err) {
      console.log(err);
    }
    history.push('/');
    setDate(Date.now());
  };

  const failFacebookAuth = () => {
    setStateSuccess(null);
  };

  const stopFacebookAuth = () => {
    setLoading(false);
  };

  const runFacebookAuth = () => {
    setLoading(true);
  };

  const handleRecoverUserSubmit = (recover) => {
    if (loading) return;
    onRecoverUserSubmit(recover, i18n.language);
  };

  return (
    <>
      <div className="new-design-login-container">
        <div className="lobby-bg-card" />
        <div className="new-design-login-container-body">
          <Row className="new-design-login-container-body-header d-flex align-items-end">
            <Col sm={12} md={6} lg={4} className="new-design-login-container-body-header-links d-flex align-items-end">
              <div className="new-design-login-container-body-header-links-section login-language d-flex align-items-center">
                <div className="divider" />
                <LanguageSelect />
              </div>
              <div className="new-design-login-container-body-header-links-section login-return d-flex align-items-center">
                <div className="divider" />
                <img src={closeImg} alt="x" onClick={goToLanding} />
              </div>
            </Col>
          </Row>

          <div className="new-design-login-container-body-content d-flex align-items-center">
            <div className="new-design-login-container-body-content-section">
              <div className="new-design-login-container-body-content-section-title d-flex align-items-center mb-4">
                <div className="login-logo">
                  <img src={logoImg} alt="x" />
                </div>
                <div className="login-close">
                  <Media src={screenMode === SCREEN_MODE.light ? lightCloseImg : closeModalImg} alt="x" onClick={goToLanding} />
                </div>
              </div>
              <div className="new-design-login-container-body-content-section-form">
                {
                  resetPasswordFlag && actionCode ? (
                    <ResetPassword
                      submitResetPassword={submitResetPassword}
                      handleInputChange={handleInputChange}
                      email={email}
                      password={password}
                      confirmPass={confirmPass}
                      lang={lang}
                      messageType={messageType}
                      error={error}
                      success={success}
                      loading={loadingSubmit}
                    />
                  ) : (
                    <LoginFormComponent
                      runFacebookAuth={runFacebookAuth}
                      passFacebookAuth={passFacebookAuth}
                      failFacebookAuth={failFacebookAuth}
                      stopFacebookAuth={stopFacebookAuth}
                      checkFacebookLogin={checkFacebookLogin}
                      history={history}
                      email={email}
                      password={password}
                      FB={FB}
                      loading={loading}
                      FBLogin={FBLogin}
                      googleLogin={googleLogin}
                      draLogin={draLogin}
                      messageType={messageType}
                      success={success}
                      stateSuccess={stateSuccess}
                      error={error}
                      stateError={stateError}
                      handleSubmit={handleSubmit}
                      handleInputChange={handleInputChange}
                      toggleForgotPassword={toggleForgotPassword}
                      toggleSignUp={toggleSignUp}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        forgotPasswordOpen && (
          <ForgotPasswordComponent
            inError={inError}
            loadingSubmit={loadingSubmit}
            handleSubmitForgotPassword={handleSubmitForgotPassword}
            messageType={messageType}
            error={error}
            success={success}
            loading={loadingSubmit}
            toggleForgotPassword={toggleForgotPassword}
            forgotPasswordOpen={forgotPasswordOpen}
            email={email}
            handleInputChange={handleInputChange}
          />
        )
      }
      {
        !termsOfUsePageOpen && toggleSignUpOpen ? (
          <SignUp
            email={email}
            name={name}
            surname={surname}
            password={password}
            confirmPass={confirmPass}
            terms={terms}
            handleSubmitRegister={handleSubmitRegister}
            toggleTermsOfUse={toggleTermsOfUse}
            toggleSignUpOpen={toggleSignUpOpen}
            toggleSignUp={toggleSignUp}
            loadingSubmit={loadingSubmit}
            messageType={messageType}
            handleInputChange={handleInputChange}
            handleChange={handleChange}
            error={error}
            success={success}
          />
        ) : termsOfUsePageOpen && toggleSignUpOpen ? (
          <CustomModal
            isOpen={termsOfUsePageOpen}
            toggle={toggleTermsOfUse}
            size="lg"
            title={t('home.termsOfUse')}
            disableFlex
            body={(
              <TermsOfUsePage />
            )}
            footer={(
              <Button color="secondary" onClick={toggleTermsOfUse}>
                {t('common.close')}
              </Button>
            )}
          />
        ) : (null)
      }
      {
        error && error === errorMessages.changedEmail && (
          <RecoverEmail recoverUser={recoverUser} toggleRecoverUser={toggleRecoverUser} error={error} loading={loadingSubmit} recoverUserSubmit={handleRecoverUserSubmit} />
        )
      }
    </>
  );
};

Login.propTypes = {
  member: PropTypes.shape({
    email: PropTypes.string,
  }),
  error: PropTypes.string,
  success: PropTypes.string,
  getDraugiemAuthUrl: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  checkFacebookLogin: PropTypes.func.isRequired,
  afterGoogleLoginFunction: PropTypes.func.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  onForgotPasswordFormSubmit: PropTypes.func.isRequired,
  loadingSubmit: PropTypes.bool,
  messageType: PropTypes.string,
  initialState: PropTypes.func.isRequired,
  resetPasswordFlag: PropTypes.bool,
  onSubmitResetPassword: PropTypes.func.isRequired,
  onRegisterFormSubmit: PropTypes.func.isRequired,
  isFullscreenEnabled: PropTypes.bool,
  fontMode: PropTypes.string,
  toggleFont: PropTypes.func.isRequired,
  newDesign: PropTypes.bool,
  setDesignFunc: PropTypes.func.isRequired,
  saveRefToken: PropTypes.func.isRequired,
};

Login.defaultProps = {
  error: null,
  success: null,
  member: {},
  loadingSubmit: false,
  messageType: stages.login,
  resetPasswordFlag: false,
  isFullscreenEnabled: false,
  fontMode: FONT_MODE.normal,
  newDesign: false,
};

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  checkFacebookLogin: checkFacebookLoginState,
  afterGoogleLoginFunction: afterGoogleLogin,
  draugiemAuthFunc: draugiemAuth,
  setDesignFunc: setDesign,
  saveRefToken: saveRefferalToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
