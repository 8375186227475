import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import {
  Button,
  Media, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap/lib';
import { connect } from 'react-redux';
import TextEditor from '../../TextEditor/TextEditor';
import { supportMessageFileUpload } from '../../../../actions/admin';
import closeImg from '../../../../images/icons/close.png';

const ItemType = 'SUBSECTION';

function HelpSubsection({
  sectionId,
  sectionIndex,
  subsectionIndex,
  subsection,
  isEdit,
  selectedId,
  collapsedIds,
  onSubsectionTitleChange,
  onSubsectionOrderChange,
  onSubsectionContentChange,
  onDeleteSubsection,
  moveSubsection,
  handleUpdateSection,
  handleEditCancel,
  toggleEdit,
  editorState,
  handleExpand,
  handleCollapse,
  supportAMessageFileUpload,
}) {
  const ref = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemType,
    collect: monitor => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current || isEdit) {
        return;
      }
      const dragIndex = item.subsectionIndex;
      const hoverIndex = subsectionIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveSubsection(sectionIndex, dragIndex, hoverIndex);
      item.subsectionIndex = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: () => ({ id: subsection.id, subsectionIndex }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isEdit,
  });

  drag(drop(ref));

  const handleTitleChange = (e) => {
    console.log(e.target.value, "check===")
    onSubsectionTitleChange(sectionId, subsection.id, e.target.value,);
  };

  const handleOrderChange = (e) => {
    console.log(e.target.value, "check===")
    onSubsectionOrderChange(sectionId, subsection.id, e.target.value,);
  };

  const handleContentChange = (state) => {
    onSubsectionContentChange(sectionId, subsection.id, state);
  };

  const handleDeleteSubsection = () => {
    setDeleteModal(true);
  };

  const chooseFile = async (file) => {
    if (file !== null) {
      try {
        const data = await supportAMessageFileUpload(file);
        return { data: { link: data.url, src: data.url } };
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteSurvey = () => {
    onDeleteSubsection(sectionId, subsection.id);
  };

  return (
    <div
      ref={!isEdit ? ref : null}
      style={{ marginLeft: '20px', opacity: isDragging ? 0.5 : 1 }}
      data-handler-id={handlerId}
      className="d-flex w-full flex-row mx-auto gap-21"
    >
      <div className='d-flex flex-row w-80'>
        <div className="w-40 d-flex flex-row">
          <div className='ml-4 text-left'>
            {isEdit && selectedId === subsection.id ? (
              <input
                type="text"
                className="w-full"
                value={subsection.orderId}
                onChange={handleOrderChange}
              />
            ) : (
              subsection.orderId
            )}
          </div>
          {isEdit && selectedId === subsection.id ? (
            <input
              type="text"
              value={subsection.title}
              onChange={handleTitleChange}
              className="w-full max-h-30"
            />
          ) : (
            subsection.title
          )}
        </div>
        <div className={`w-60 ${!collapsedIds.includes(subsection.id) ? 'h-auto' : 'max-h-5-lines'}`}>
          {isEdit && selectedId === subsection.id ? (
            <TextEditor editorState={editorState} chooseFile={chooseFile} editorStateSet notChangedContent handleEditState={handleContentChange} />
          ) : (
            <TextEditor viewEditorState={subsection.content} readOnly originalPadding />
          )}
        </div>
      </div>
      <div className='d-flex flex-row w-20 gap-10'>
        <div className='w-20'>
          {isEdit && selectedId === subsection.id ? (
            <div className='d-flex flex-column gap-10'>
              <Button size="sm" color="warning" className='mr-1' onClick={() => handleUpdateSection(subsection.id)}>Save</Button>
              <Button size="sm" color="warning" onClick={() => handleEditCancel(subsection.id)}>Cancel</Button>
            </div>
          ) : (
            <Button size="sm" color="warning" onClick={() => toggleEdit(subsection.id, subsection.content)} disabled={isEdit && selectedId !== subsection.id}>Edit</Button>
          )}
        </div>
        <div className="w-50 d-flex flex-column gap-10">
          <Button size="sm" color="danger" onClick={handleDeleteSubsection} disabled={isEdit}>Delete Subsection</Button>
        </div>
        <div className="w-30">
          <Button size="sm" color="success" className="mr-2" onClick={() => handleCollapse(subsection.id)} disabled={isEdit}>{collapsedIds.includes(subsection.id) && !isEdit ? 'Expand' : 'Collapse'}</Button>
        </div>
      </div>
      <Modal container={'div > div'} isOpen={deleteModal} toggle={() => toggleDeleteModal()} className="notification">
        <ModalHeader
          toggle={() => toggleDeleteModal()}
          className="notification-header"
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => toggleDeleteModal()} />
          }
        />
        <ModalBody className="notification-body">
          Do you really want to delete this subsection?
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button className="btn notification-footer-button" onClick={() => handleDeleteSurvey()}>
            Delete
          </Button>
          <Button type="button" className="btn notification-footer-button" onClick={() => toggleDeleteModal()}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

HelpSubsection.propTypes = {
  sectionId: PropTypes.string,
  sectionIndex: PropTypes.string,
  subsectionIndex: PropTypes.string,
  subsection: PropTypes.shape({}),
  isEdit: PropTypes.bool,
  selectedId: PropTypes.string,
  collapsedIds: PropTypes.array,
  onSubsectionTitleChange: PropTypes.func.isRequired,
  onSubsectionOrderChange: PropTypes.func.isRequired,
  onSubsectionContentChange: PropTypes.func.isRequired,
  onDeleteSubsection: PropTypes.func.isRequired,
  moveSubsection: PropTypes.func.isRequired,
  handleUpdateSection: PropTypes.func.isRequired,
  handleEditCancel: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  editorState: PropTypes.shape({}),
  handleExpand: PropTypes.func.isRequired,
  handleCollapse: PropTypes.func.isRequired,
  supportAMessageFileUpload: PropTypes.func.isRequired,
};

HelpSubsection.defaultProps = {
  sectionId: null,
  sectionIndex: null,
  subsectionIndex: null,
  subsection: {},
  isEdit: false,
  selectedId: null,
  editorState: null,
};

const mapDispatchToProps = {
  supportAMessageFileUpload: supportMessageFileUpload,
};

export default connect(
  null,
  mapDispatchToProps,
)(HelpSubsection);
