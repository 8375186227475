import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, slice, size } from 'lodash';

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
} from 'reactstrap';

import { UncontrolledPopover } from 'reactstrap/lib';
import PossibleBotPlayerData from './PossibleBotPlayerData';


import {
  getPossibleBotPlayerData,
  getPossibleBotPlayers,
} from '../../../actions/admin';
import DateFormat from '../UI/DateFormat';
import { FORMAT_DATE_TYPE } from '../../../constants/constants';

const PossibleBotPlayers = ({}) => {
  const dispatch = useDispatch();
  const [selectedBot, setSelectedBot] = useState('');
  const [openDetailView, setOpenDetailView] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 50;

  useEffect(() => {
    dispatch(getPossibleBotPlayers());
  }, []);

  useEffect(() => {
    if (selectedBot) {
      dispatch(getPossibleBotPlayerData(selectedBot));
    }
  }, [selectedBot]);

  const possibleBotPlayers = useSelector(state => state.admin.possibleBotPlayers || {});
  const possibleBotPlayerData = useSelector(state => state.admin.possibleBotPlayerData || {});
  const possibleBotLoading = useSelector(state => state.admin.possibleBotLoading || false);
  const pagesCount = possibleBotPlayers ? Math.ceil(Object.keys(possibleBotPlayers).length / pageSize) : 0;

  const openDetailViewModal = (key) => {
    setOpenDetailView(true);
    setSelectedBot(key);
  };

  const closeDetailViewModal = () => {
    setOpenDetailView(false);
    setSelectedBot('');
  };

  const handleClick = (e, index) => {
    e.preventDefault();

    setCurrentPage(index);
  };

  return (
    <Fragment>
      <UncontrolledPopover popperClassName="popover" placement="right" trigger="focus" target="admin-help-button-possible-bot-players">
        <PopoverBody className="popover-body">
          A list of players who meet at least one of the following: have played in more than 3 rooms per 5 minutes, played more than 5 games per 5 minutes, or played in the auto-play in at least 3 rooms in a row. Can be used to quickly check if the player is not a possible bot account.
        </PopoverBody>
      </UncontrolledPopover>
      <div className="admin-page-content">
        <Row>
          <Col sm="4">
            <h2>
              Possible Bots
              <Button className="admin-help-button" id="admin-help-button-possible-bot-players">
                ?
              </Button>
            </h2>
          </Col>
        </Row>
        <table style={{ width: '100%', fontSize: 12 }}>
          <colgroup>
            <col span="1" className="" />
          </colgroup>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>
                Date
              </th>
              <th style={{ textAlign: 'center' }}>
                Name
              </th>
              <th style={{ textAlign: 'center' }}>
                Player ID
              </th>
              <th style={{ textAlign: 'center' }}>
                Events
              </th>
              <th style={{ textAlign: 'center' }}>
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {possibleBotLoading ? (
              <Spinner type="grow" color="warning" style={{ width: '2rem', height: '2rem' }} />
            ) : (
              possibleBotPlayers
                && Object.keys(possibleBotPlayers)
                  .sort((a, b) => possibleBotPlayers[b].date - possibleBotPlayers[a].date)
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map((key, idx) => (
                    <tr key={key} className="allUsers-table-row">
                      <td className="allUsers-table-col">
                        <DateFormat formatType={FORMAT_DATE_TYPE.fullDateMinute} date={possibleBotPlayers[key].date} />
                      </td>
                      <td className="allUsers-table-col">
                        {possibleBotPlayers[key].name}
                      </td>
                      <td className="allUsers-table-col">
                        {possibleBotPlayers[key].uid}
                      </td>
                      <td className="allUsers-table-col">
                        {possibleBotPlayers[key].count}
                      </td>
                      <td className="allUsers-table-col">
                        <Button color="warning" onClick={() => openDetailViewModal(key)}>
                          View
                        </Button>
                      </td>
                    </tr>
                  ))
            )}
            {pagesCount && size(possibleBotPlayers) > pageSize && (
              <div className="pagination-wrapper">
                <Pagination aria-label="Page navigation example">
                  <PaginationItem disabled={currentPage === 0}>
                    <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, 0)} href="#">
                      {`‹‹‹`}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink
                      style={{ color: '#000' }}
                      onClick={e => handleClick(e, currentPage - 1)}
                      previous
                      href="#"
                    />
                  </PaginationItem>

                  {[...Array(pagesCount)].map((page, i) => {
                    if (i > currentPage - 3 && i < currentPage + 3) {
                      return (
                        <PaginationItem active={i === currentPage} key={page}>
                          <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, i)} href="#">
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }

                    return null;
                  })}

                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink
                      style={{ color: '#000' }}
                      onClick={e => handleClick(e, currentPage + 1)}
                      next
                      href="#"
                    />
                  </PaginationItem>

                  <PaginationItem disabled={currentPage >= pagesCount - 1}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => handleClick(e, pagesCount - 1)} href="#">
                        {`›››`}
                      </PaginationLink>
                    </PaginationItem>
                </Pagination>
              </div>
            )}
          </tbody>
        </table>
      </div>
      <Modal container={'div > div'} returnFocusAfterClose={false} size="xlg" isOpen={openDetailView} toggle={closeDetailViewModal}>
        <ModalHeader toggle={closeDetailViewModal}>
          Detail View
        </ModalHeader>
        <ModalBody>
          <PossibleBotPlayerData possibleBotPlayerData={possibleBotPlayerData} />
        </ModalBody>
        <ModalFooter>
          <Button className="modal-footer-button" onClick={() => closeDetailViewModal()}>Close</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default PossibleBotPlayers;
