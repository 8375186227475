import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { constant, map, filter, includes, size } from 'lodash';

import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Media from 'reactstrap/lib/Media';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { connect } from 'react-redux';

import Moment from 'react-moment';
import moment from 'moment';

import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

import DateFormat from '../UI/DateFormat';

import pencilImg from '../../../images/icons/pencil.svg';
import checkImg from '../../../images/icons/check.png';
import cancelImg from '../../../images/icons/close_old.png';
import closeImg from '../../../images/icons/close.png';

import * as constants from '../../../constants/constants';

import {
  getAllShopDiscounts,
  addShopDiscount,
  supportMessageFileUpload,
  deleteShopDiscount,
  editShopDiscount,
} from '../../../actions/admin';
import { errorMessages } from '../../../constants/messages';
import { PopoverBody, Table, UncontrolledPopover } from 'reactstrap';

moment.locale('lv');
momentLocalizer();

class ShopDiscounts extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    supportAMessageFileUpload: PropTypes.func.isRequired,
    allShopDiscounts: PropTypes.shape([]),
    fetchAllShopDiscounts: PropTypes.func.isRequired,
    addAShopDiscount: PropTypes.func.isRequired,
    editAShopDiscount: PropTypes.func.isRequired,
    deleteAShopDiscount: PropTypes.func.isRequired,
  }

  static defaultProps = {
    allShopDiscounts: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      filterType: 'ALL',
      currentPage: 0,
      pageSize: 50,
      enTitle: '',
      enDescription: '',
      preview: null,
      imageUrl: '',
      startDate: new Date(),
      endDate: new Date(),
      showInBanner: false,
      uploadFile: null,
      ableToSave: false,
      newEventModalOpen: false,
      deleteModal: false,
      selectEventId: '',
      openModal: false,
      type: constants.SPECIALPRICE,
      value: '',
      loading: false,
      oldData: {},
      lvTitle: '',
      ruTitle: '',
      lvDescription: '',
      ruDescription: '',
      devMode: false,
      specialPrice: {
        money1: 0,
        money2: 0,
        money3: 0,
        money4: 0,
        money5: 0,
        money6: 0,
        money7: 0,
        money8: 0,
        money9: 0,
        hideAdsMonth: 0,
        hideAdsYear: 0,
      },
      discountPrice: 0,
      bounsPrice: 0,
      // selectedDiscountPackage: ['money1', 'money2', 'money3', 'money4', 'money5', 'money6', 'money7', 'money8', 'money9'],
      selectedDiscountPackage: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.hiddenFileInput = React.createRef(null);
    this.handleEditPhoto = this.handleEditPhoto.bind(this);
    this.onCrop = this.onCrop.bind(this);
  }

  componentDidMount() {
    const { fetchAllShopDiscounts } = this.props;
    const { filterType } = this.state;

    fetchAllShopDiscounts(filterType);
  }

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }

  // edit existing admin functions
  toggleDeleteModal = (key) => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
      selectEventId: key,
    }));
  }

  changeDiscountOption = (e) => {
    const { selectedDiscountPackage, type } = this.state;
    if (e.target.value !== type) {
      if (e.target.value === constants.BONUS) {
        const temp = ['money1', 'money2', 'money3', 'money4', 'money5', 'money6', 'money7', 'money8', 'money9', 'hideAdsMonth', 'hideAdsYear'];
        temp.splice(5, 9);
        this.setState({
          selectedDiscountPackage: temp,
        });
      } else if (e.target.value === constants.DISCOUNTPRICE) {
        this.setState({
          selectedDiscountPackage: ['money1', 'money2', 'money3', 'money4', 'money5', 'money6', 'money7', 'money8', 'money9', 'hideAdsMonth', 'hideAdsYear'],
        });
      } else {
        this.setState({
          selectedDiscountPackage: [],
        });
      }
      this.setState({
        type: e.target.value,
      });
    }
  }

  deleteShopDiscount = () => {
    const { deleteAShopDiscount, showNotification, t } = this.props;
    const { selectEventId } = this.state;

    if (selectEventId !== '') {
      this.setState({
        loading: true,
      });
      deleteAShopDiscount(selectEventId).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Discount have been successfully deleted.', 'success');
        }

        if (res.status === 'error') {
          showNotification('Error!', res.message, 'danger');
        }
        this.setState({
          deleteModal: false,
          selectEventId: '',
          loading: false,
        });
      });
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
    this.initStateValue();
  }

  openModal = (id) => {
    const { allShopDiscounts } = this.props;
    const { specialPrice, selectedDiscountPackage} = this.state;
    const selectEvent = allShopDiscounts[id];
    this.setState({
      selectEventId: selectEvent.key,
      enTitle: selectEvent.titleEN || '',
      lvTitle: selectEvent.titleLV || '',
      ruTitle: selectEvent.titleRU || '',
      startDate: new Date(selectEvent.startDate) || null,
      endDate: new Date(selectEvent.endDate) || null,
      enDescription: selectEvent.descriptionEN || '',
      lvDescription: selectEvent.descriptionLV || '',
      ruDescription: selectEvent.descriptionRU || '',
      imageUrl: selectEvent.imageUrl || '',
      type: selectEvent.type || constants.SPECIALPRICE,
      value: selectEvent.value || null,
      showInBanner: selectEvent.showInBanner || false,
      devMode: selectEvent.devMode || false,
      openModal: true,
      deleteModal: false,
      newEventModalOpen: false,
      oldData: selectEvent,
    });
    if (selectEvent.type === constants.SPECIALPRICE) {
      this.setState({
        specialPrice: {
          ...selectEvent.specialPrice,
        },
        selectedDiscountPackage: selectEvent?.selectedDiscountPackage,
      });
    } else {
      this.setState({
        discountPrice: parseInt(selectEvent?.discountPrice) || 0,
        selectedDiscountPackage: selectEvent?.selectedDiscountPackage,
      });
    }
  }

  // Add new admin functions
  openAddEventModal = () => {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      enTitle: '',
      ruTitle: '',
      lvTitle: '',
      enDescription: '',
      lvDescription: '',
      ruDescription: '',
      imageUrl: null,
      preview: null,
      showCrop: false,
      value: '',
      showInBanner: false,
      devMode: false,
      ableToSave: false,
      openModal: false,
      newEventModalOpen: true,
      oldData: {},
      specialPrice: {
        money1: 0,
        money2: 0,
        money3: 0,
        money4: 0,
        money5: 0,
        money6: 0,
        money7: 0,
        money8: 0,
        money9: 0,
        hideAdsMonth: 0,
        hideAdsYear: 0,
      },
      discountPrice: 0,
      bounsPrice: 0,
      selectedDiscountPackage: [],
    });
  }

  closeShopDiscountModal = () => {
    this.setState({ newEventModalOpen: false });
  }

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  initStateValue = () => {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      enTitle: '',
      ruTitle: '',
      lvTitle: '',
      enDescription: '',
      lvDescription: '',
      ruDescription: '',
      imageUrl: null,
      preview: null,
      showCrop: false,
      showInBanner: false,
      devMode: false,
      ableToSave: false,
      type: constants.SPECIALPRICE,
      value: null,
      newEventModalOpen: false,
      oldData: {},
      specialPrice: {
        money1: 0,
        money2: 0,
        money3: 0,
        money4: 0,
        money5: 0,
        money6: 0,
        money7: 0,
        money8: 0,
        money9: 0,
        hideAdsMonth: 0,
        hideAdsYear: 0,
      },
      discountPrice: 0,
      bounsPrice: 0,
      selectedDiscountPackage: [],
    });
  }

  addShopDiscount = async () => {
    const { addAShopDiscount, showNotification, t } = this.props;
    const {
      startDate,
      endDate,
      enTitle,
      lvTitle,
      ruTitle,
      enDescription,
      lvDescription,
      ruDescription,
      imageUrl,
      type,
      value,
      showInBanner,
      showCrop,
      ableToSave,
      devMode,
      specialPrice,
      discountPrice,
      selectedDiscountPackage,
    } = this.state;
    console.log({showCrop}, {ableToSave}, "check=================")

    if (!showCrop && !ableToSave) {
      this.setState({
        loading: true,
      });
      if (!startDate) {
        this.setState({ loading: false });
        return showNotification('Error', 'please input the Start time.', 'danger');
      }
      if (!endDate) {
        this.setState({ loading: false });
        return showNotification('Error', 'please input the End time.', 'danger');
      }
      if (Date.parse(startDate) > Date.parse(endDate)) {
        this.setState({ loading: false });
        return showNotification('Error', 'End time cannot be before the start time.', 'danger');
      }
      await addAShopDiscount({
        startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, type, value, showInBanner, devMode, specialPrice, discountPrice, selectedDiscountPackage,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', "Event successfully added.", 'success');
          this.initStateValue();
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error!', 'Missed the data.', 'danger');
          } else if (res.message === errorMessages.wrongEventDate) {
            showNotification('Error!', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLVTitle) {
            showNotification('Error!', "Missed the title by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENTitle) {
            showNotification('Error!', "Missed the title by English.", 'danger');
          } else if (res.message === errorMessages.missingRUTitle) {
            showNotification('Error!', "Missed the title by Russian.", 'danger');
          } else if (res.message === errorMessages.missingLVDescription) {
            showNotification('Error!', "Missed the Description by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENDescription) {
            showNotification('Error!', "Missed the Description by English.", 'danger');
          } else if (res.message === errorMessages.missingRUDescription) {
            showNotification('Error!', "Missed the Description by Russian.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error!', "Please upload the event image.", 'danger');
          } else if (res.message === errorMessages.missingValue) {
            showNotification('Error!', "Missing the amount of game coins which will be added upon activating it.", 'danger');
          } else {
            showNotification('Error!', res.message, 'danger');
          }
        }

        this.setState({
          loading: false,
        });
      }).catch((error) => {
        this.setState({
          loading: false,
        });
        return showNotification('Error!', error.message, 'danger');
      });
    } else {
      this.setState({
        loading: false,
      });
      return showNotification(t('warning'), "If you don't apply or close uploaded image, you can't add new event.", 'warning');
    }
  }

  editShopDiscount = () => {
    const { editAShopDiscount, showNotification, t } = this.props;
    const {
      selectEventId,
      startDate,
      endDate,
      enTitle,
      lvTitle,
      ruTitle,
      enDescription,
      lvDescription,
      ruDescription,
      imageUrl,
      type,
      value,
      showInBanner,
      showCrop,
      ableToSave,
      oldData,
      devMode,
      specialPrice,
      discountPrice,
      selectedDiscountPackage,
    } = this.state;
    if (!showCrop && !ableToSave) {
      this.setState({
        loading: true,
      });
    //  console.log(specialPrice);
      if (!startDate) {
        this.setState({ loading: false });
        return showNotification('Error', 'please input the Start time.', 'danger');
      }
      if (!endDate) {
        this.setState({ loading: false });
        return showNotification('Error', 'please input the End time.', 'danger');
      }
      if (Date.parse(startDate) > Date.parse(endDate)) {
        this.setState({ loading: false });
        return showNotification('Error', 'End time cannot be before the start time.', 'danger');
      }
      editAShopDiscount({
        selectEventId, startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, type, value, showInBanner, oldData, devMode, specialPrice, discountPrice, selectedDiscountPackage,
      }).then((res) => {
        if (res && res.status === 'success') {
          showNotification('Success', 'Changes have been successfully saved.', 'success');
          this.initStateValue();
        }

        if (res.status === 'error') {
          if (res.message === errorMessages.missingData) {
            showNotification('Error!', 'Missed the data.', 'danger');
          } else if (res.message === errorMessages.wrongEventDate) {
            showNotification('Error!', "The End date was inputed wrong.", 'danger');
          } else if (res.message === errorMessages.missingLVTitle) {
            showNotification('Error!', "Missed the title by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENTitle) {
            showNotification('Error!', "Missed the title by English.", 'danger');
          } else if (res.message === errorMessages.missingRUTitle) {
            showNotification('Error!', "Missed the title by Russian.", 'danger');
          } else if (res.message === errorMessages.missingLVDescription) {
            showNotification('Error!', "Missed the Description by Latvian.", 'danger');
          } else if (res.message === errorMessages.missingENDescription) {
            showNotification('Error!', "Missed the Description by English.", 'danger');
          } else if (res.message === errorMessages.missingRUDescription) {
            showNotification('Error!', "Missed the Description by Russian.", 'danger');
          } else if (res.message === errorMessages.missingPhoto) {
            showNotification('Error!', "Please upload the event image.", 'danger');
          } else if (res.message === errorMessages.missingValue) {
            showNotification('Error!', "Missing the amount of game coins which will be added upon activating it.", 'danger');
          } else {
            showNotification('Error!', res.message, 'danger');
          }
        }

        this.setState({
          openModal: false,
          loading: false,
        });
      }).catch(error => {
        this.setState({
          loading: false,
        });
        return showNotification('Error!', error.message, 'danger');
      });
    } else {
      return showNotification(t('warning'), "If you don't apply or close uploaded image, you can't add new event.", 'warning');
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  selectType = (e) => {
    const { fetchAllShopDiscounts } = this.props;
    this.setState({ filterType: e.target.value });

    fetchAllShopDiscounts(e.target.value);
  }

  onCrop = (preview) => {
    this.setState({ preview });
  }

  handleEditPhoto = (value) => {
    this.setState({ showCrop: !value });
  }

  uploadEventFile = () => {
    const { supportAMessageFileUpload } = this.props;
    const { uploadFile } = this.state;

    this.setState({ ableToSave: true });
    if (uploadFile !== null) {
      supportAMessageFileUpload(uploadFile).then((data) => {
        this.setState({
          imageUrl: data.url,
          ableToSave: false,
        });
      }).catch((e) => {
        this.setState({
          preview: null,
          ableToSave: false,
        });
      });
    } else {
      this.setState({ ableToSave: false });
    }
  }

  handleImageClick = () => {
    this.hiddenFileInput.current.click();
  };

  _handleImageChange = (e) => {
    e.preventDefault();

    try {
      const { showNotification, t } = this.props;
      const typeList = 'image/jpg,image/png,image/jpeg, image/pjp, image/pjpeg, image/jfif';
      const reader = new FileReader();
      const file = e.target.files[0];

      if (!typeList.includes(file.type) || file.type === '') {
        showNotification('Error', "Unsupported file!", 'danger');
        return e.target.value === '';
      }

      if (file.size / 1024 > 3072) {
        showNotification('Warning', "You can't upload more than 3MB", 'warning');
        return e.target.value === '';
      }

      reader.onloadend = () => {
        this.setState({
          preview: reader.result,
          uploadFile: file,
        });
      };
      reader.readAsDataURL(file);
    } catch (err) {
      const { showNotification, t } = this.props;
      showNotification('Error!', err.message, 'danger');

      return e.target.value === '';
    }
  }

  toggleShowInBanner = () => {
    const { showInBanner } = this.state;

    this.setState({
      showInBanner: !showInBanner,
    });
  }

  toggleDevMode = () => {
    this.setState(prevState => ({
      devMode: !prevState.devMode,
    }));
  }

  handleSpecialPrice = (event) => {
    const { specialPrice } = this.state;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      specialPrice: {
        ...specialPrice,
        [event.target.name]: value,
      },
    });
  }

  handleSelectPackage = (event, realPrice) => {
    const { selectedDiscountPackage } = this.state;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    const temp = selectedDiscountPackage ? [...selectedDiscountPackage] : [];
    const index = temp.indexOf(event.target.name);
    const { specialPrice, type } = this.state;
    const tempPrice = {...specialPrice};

    if (index === -1) {
      temp.push(event.target.name);
      tempPrice[event.target.name] = realPrice;
    } else {
      temp.splice(index, 1);
      tempPrice[event.target.name] = 0;
    }
    if (type === constants.SPECIALPRICE) {
      this.setState({
        selectedDiscountPackage: temp,
        specialPrice: tempPrice,
      });
    } else {
      this.setState({
        selectedDiscountPackage: temp,
      });
    }
    
  }

  table() {
    const {
      t,
      allShopDiscounts,
      i18n,
    } = this.props;

    const {
      currentPage,
      pageSize,
    } = this.state;
    const pagesCount = Math.ceil(Object.keys(allShopDiscounts).length / pageSize);
  //  console.log(allShopDiscounts, "all discount")
    return (
      <Fragment>
        {Object.keys(allShopDiscounts).length > 0 && Object.keys(allShopDiscounts)
          .slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize,
          )
          .map((key, index) => (
            <Fragment key={key}>
              <tr key={key} className={`allUsers-table-row text-md ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allShopDiscounts[key].startDate} />
                </td>
                <td className="allUsers-table-col">
                  <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateMinute} date={allShopDiscounts[key].endDate} />
                </td>
                <td className="allUsers-table-col">
                  
                  {i18n.language === 'en' ? allShopDiscounts[key].titleEN : (i18n.language === 'lv' ? allShopDiscounts[key].titleLV : (i18n.language === 'ru' ? allShopDiscounts[key].titleRU : allShopDiscounts[key].titleLV))}
                </td>
                <td className="allUsers-table-col">
                  {i18n.language === 'en' ? allShopDiscounts[key].descriptionEN : (i18n.language === 'lv' ? allShopDiscounts[key].descriptionLV : (i18n.language === 'ru' ? allShopDiscounts[key].descriptionRU : allShopDiscounts[key].descriptionLV))}
                </td>
                <td className="allUsers-table-col">
                  {allShopDiscounts[key].type}
                </td>
                <td className="allUsers-table-col">
                  {
                    allShopDiscounts[key].type === constants.SPECIALPRICE ? (
                      map(allShopDiscounts[key].specialPrice, (item, key) => (
                        <Row>
                          <Col sm="6">{filter(constants.SHOP_PRODUCT_TYPES, product => product.label === key)[0].productNr}:</Col>
                          <Col sm="5">{item || 0}</Col>
                        </Row>
                      ))
                    ) : allShopDiscounts[key].type === constants.DISCOUNTPRICE ? (
                      <>
                        <Row>
                          <Col sm="6">{constants.DISCOUNTPRICE}</Col>
                          <Col sm="5">{allShopDiscounts[key].discountPrice}</Col>
                        </Row>
                        <Row>
                          {
                            map(allShopDiscounts[key].selectedDiscountPackage, (item) => (
                              <Col sm="12">{filter(constants.SHOP_PRODUCT_TYPES, product => product.label === item)[0].productNr}</Col>
                            ))
                          }
                        </Row>
                      </>
                    ) : allShopDiscounts[key].type === constants.BONUS ? (
                      <>
                        <Row>
                          <Col sm="6">{constants.BONUS}</Col>
                          <Col sm="6">{allShopDiscounts[key].discountPrice}</Col>
                        </Row>
                        <Row>
                          {
                            map(allShopDiscounts[key].selectedDiscountPackage, (item) => (
                              <Col sm="12">{filter(constants.SHOP_PRODUCT_TYPES, product => product.label === item)[0].productNr}</Col>
                            ))
                          }
                        </Row>
                      </>
                    ) : (null)
                  }
                </td>
                <td className="allUsers-table-col">
                  {allShopDiscounts[key].showInBanner ? 'Show' : 'Not Show'}
                </td>
                <td className="allUsers-table-col">
                  {allShopDiscounts[key].devMode ? t('yes') : t('no')}
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.openModal(key)}>
                    {/* {t('edit')} */}
                    Edit
                  </Button>
                </td>
                <td className="allUsers-table-col">
                  <Button color="primary" onClick={() => this.toggleDeleteModal(allShopDiscounts[key].key )}>
                    {/* {t('delete')} */}
                    Delete
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {pagesCount && Object.keys(allShopDiscounts).length > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              
            <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 3 && i < currentPage + 3) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                  <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                    {`›››`}
                  </PaginationLink>
                </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      t,
    } = this.props;

    const {
      newEventModalOpen,
      filterType,
      enTitle,
      enDescription,
      preview,
      imageUrl,
      startDate,
      endDate,
      showCrop,
      showInBanner,
      deleteModal,
      openModal,
      type,
      value,
      loading,
      ableToSave,
      lvTitle,
      ruTitle,
      lvDescription,
      ruDescription,
      devMode,
      specialPrice,
      discountPrice,
      bounsPrice,
      selectedDiscountPackage,
    } = this.state;

   console.log(specialPrice, "special price");
  //  console.log(selectedDiscountPackage, "selected package");

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-shop-discounts">
          <PopoverBody className="popover-body">
            The administration can add a shop discount with one of the following - discounted price, special price, or a bonus. Each discount has to have a title and description in all listed languages, and also a banner image.
          </PopoverBody>
        </UncontrolledPopover>
        <div className="admin-page-content">
          <Row>
            <Col sm="4">
              <h2>
                {/* {t('shopDiscounts')} */}
                Shop Discounts
                <Button className="admin-help-button" id="admin-help-button-shop-discounts">
                  ?
                </Button>
              </h2>
            </Col>
            <Col sm="4">
              <Input type="select" className="event-type-select" value={filterType} onChange={this.selectType}>
                <option value="ALL" className="background-222">All Events</option>
                <option value="PAST" className="background-222">Past Events</option>
                <option value="CURRENT" className="background-222">Current Events</option>
                <option value="PLAN" className="background-222">Planned Events</option>
              </Input>
            </Col>
            <Col sm="4">
              <Button color="primary" onClick={this.openAddEventModal}>
                {/* {t('addEvent')} */}
                Add Event
              </Button>
            </Col>
          </Row>
          <Table striped bordered style={{ width: '100%', fontSize: 12, color: '#fff' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('startDate')} */}
                  Start Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('endDate')} */}
                  End Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('title')} */}
                  Title
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('description')} */}
                  Description
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('discountOption')} */}
                  Discount Option
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('discountPercent')} */}
                  Discout Percent
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('showOption')} */}
                  Show In Banner
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('devMode')} */}
                  Only Dev
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('edit')} */}
                  Edit
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('delete')} */}
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </Table>

        </div>
        {/* Delete Event modal */}
        <Modal container={'div > div'} isOpen={deleteModal} toggle={() => this.toggleDeleteModal('')} className="notification">
          <ModalHeader
            toggle={() => this.toggleDeleteModal('')}
            className="notification-header"
            close={
              <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.toggleDeleteModal('')} />
            }
          />
          <ModalBody className="notification-body">
            {/* {t('deleteDiscountQuiz')} */}
            Do you really want to delete the discount event?
          </ModalBody>
          <ModalFooter className="notification-footer">
            <Button className="btn notification-footer-button" disabled={loading} onClick={this.deleteShopDiscount}>
              {loading ? 'Loading' : 'Yes'}
            </Button>
            <Button type="button" className="btn notification-footer-button" disabled={loading} onClick={() => this.toggleDeleteModal('')}>
              {loading ? 'Loading' : 'No'}
            </Button>
          </ModalFooter>
        </Modal>

        {/* Edit Event modal */}
        <Modal container={'div > div'} eturnFocusAfterClose={false} size="lg" isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {/* {t('edit')} */}
            Edit
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="startDate">
                  {/* {t('startDate')} */}
                  Start Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeStartDate}
                      value={startDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  {/* {t('endDate')} */}
                  End Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeEndDate}
                      value={endDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('lvTitle')} */}
                  Title By Latvian
                </Label>
                <Input
                  type="text"
                  name="lvTitle"
                  id="lvTitle"
                  value={lvTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('enTitle')} */}
                  Title By English
                </Label>
                <Input
                  type="text"
                  name="enTitle"
                  id="enTitle"
                  value={enTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('ruTitle')} */}
                  Title By Russian
                </Label>
                <Input
                  type="text"
                  name="ruTitle"
                  id="ruTitle"
                  value={ruTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lvDescription">
                  {/* {t('lvDescription')} */}
                  Description By Latvian
                </Label>
                <Input
                  type="textarea"
                  name="lvDescription"
                  id="lvDescription"
                  value={lvDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enDescription">
                  {/* {t('enDescription')} */}
                  Description By English
                </Label>
                <Input
                  type="textarea"
                  name="enDescription"
                  id="enDescription"
                  value={enDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruDescription">
                  {/* {t('ruDescription')} */}
                  Description By Russian
                </Label>
                <Input
                  type="textarea"
                  name="ruDescription"
                  id="ruDescription"
                  value={ruDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  {/* {t('bannerImage')} */}
                  Banner Image
                </Label>
                <div className="banner-image">
                  {
                    !preview && !imageUrl ? (
                      <Label className="banner-image-label">Please upload the banner image for event.</Label>
                    ) : (
                      <img className="event-banner-image" src={preview || imageUrl} alt="Preview" />
                    )
                  }
                </div>
                {!showCrop && (
                <div className="event-banner-edit">
                  <Media onClick={() => { this.handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" disabled={loading} />
                </div>
                )}
                {!showCrop && (
                <div className="event-banner-cancel">
                  <Media onClick={() => { this.setState({ imageUrl: '', preview: '' }); }} src={cancelImg} className="menu-player-edit-image" disabled={loading} />
                </div>
                )}
                {showCrop && (
                <div className="event-banner-edit">
                  <Media onClick={() => { this.handleEditPhoto(showCrop); this.uploadEventFile(); }} src={checkImg} className="menu-player-edit-image" />
                </div>
                )}
                {showCrop && (
                <div className="event-banner-cancel">
                  <Media onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} src={cancelImg} className="menu-player-edit-image" />
                </div>
                )}
                {
                  showCrop && (
                  <div>
                    <div className="event-banner-button" onClick={this.handleImageClick}>
                      {/* {t('uploadImageButton')} */}
                      Upload a Image
                    </div>
                    <input
                      type="file"
                      ref={this.hiddenFileInput}
                      onChange={this._handleImageChange}
                      style={{ display: 'none' }}
                      accept="image/*"
                    />
                  </div>
                  )
                }
                <Input
                  type="text"
                  name="imageUrl"
                  id="imageUrl"
                  placeholder=""
                  hidden
                  readOnly
                  value={imageUrl}
                />
              </FormGroup>
              <FormGroup>
                <Label for="type">
                  {/* {t('discountOption')} */}
                  Discount Option
                </Label>
                <Input type="select" name="type" className="discount-option-select" value={type} disabled={loading} onChange={this.changeDiscountOption}>
                  <option className="background-222" value={constants.SPECIALPRICE}>Special Price</option>
                  <option className="background-222" value={constants.DISCOUNTPRICE}>Discounted Price</option>
                  <option className="background-222" value={constants.BONUS}>Bonus</option>
                </Input>
              </FormGroup>
              {console.log(specialPrice, "=checkSpecialPrice")}
              <FormGroup>
                {
                  type === constants.SPECIALPRICE ? (
                    <Row>
                      <Col sm="12" style={{borderBottom: '1px solid #fff'}}>
                        <Row>
                          {
                            constants.SHOP_PRODUCT_TYPES && map(constants.SHOP_PRODUCT_TYPES, (item, key) => (
                              <>
                                <Col sm="6">
                                  <FormGroup>
                                    <Input type="checkbox" className="ml-0" name={item.label} disabled={loading} checked={includes(selectedDiscountPackage, item.label) || false} onClick={(e) => this.handleSelectPackage(e, item.realPrice)} readOnly />
                                    <Label for="type" className="ml-5">
                                      {item.productNr}
                                    </Label>
                                    <Input
                                      type="number"
                                      name={item.label}
                                      id={item.label}
                                      disabled={loading || !includes(selectedDiscountPackage, item.label)}
                                      value={includes(selectedDiscountPackage, item.label) ? specialPrice[item.label] : ''}
                                      onChange={this.handleSpecialPrice}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </FormGroup>
                                </Col>
                              </>
                            ))
                          }
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <FormGroup>
                        <Label for="discountPrice">
                          {/* {t('discountPrice')} */}
                          Discount Price
                        </Label>
                        <Input
                          type="number"
                          name="discountPrice"
                          id="discountPrice"
                          disabled={loading}
                          value={parseInt(discountPrice)}
                          onChange={this.handleChange}
                          onWheel={(e) => e.target.blur()}
                        />
                      </FormGroup>
                      <Row>
                        <Col sm="12" style={{borderBottom: '1px solid #fff' }}>
                          <Row>
                            {
                              constants.SHOP_PRODUCT_TYPES && map(constants.SHOP_PRODUCT_TYPES, (item, key) => (
                                <>
                                  <Col sm="6" >
                                    <FormGroup>
                                      <Label className="event-type-input" style={{ marginLeft: 40 }}>
                                        <Input type="checkbox" name={item.label} onClick={this.handleSelectPackage} disabled={loading} checked={selectedDiscountPackage?.includes(item.label) || false} readOnly />
                                        <span className="checkmark" />
                                        <div className="event-type-text">
                                          {item.productNr}
                                        </div>
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                </>
                              ))
                            }
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )
                }
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleShowInBanner} disabled={loading} checked={showInBanner} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('showInBanner')} */}
                        Would you like to show event in banner Image?
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleDevMode} disabled={loading} checked={devMode} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('devMode')} */}
                        Only Dev
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" disabled={loading || ableToSave} onClick={this.editShopDiscount}>{loading ? 'Loading' : 'Save'}</Button>
            <Button className="modal-footer-button" disabled={loading || ableToSave} onClick={this.toggle}>{loading ? 'Loading' : 'Close'}</Button>
          </ModalFooter>
        </Modal>

        {/* Add Shop Discount Event modal */}
        <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={newEventModalOpen} toggle={this.closeShopDiscountModal}>
          <ModalHeader toggle={this.closeShopDiscountModal}>
            {/* {t('addShopDiscount')} */}
            Add Shop Discount
          </ModalHeader>
          <ModalBody>
            <Form className="form-style">
              <FormGroup>
                <Label for="startDate">
                  {/* {t('startDate')} */}
                  Start Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeStartDate}
                      value={startDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="endDate">
                  {/* {t('endDate')} */}
                  End Date
                </Label>
                <Row>
                  <Col md="12">
                    <DateTimePicker
                      format="DD.MM.YYYY HH:mm"
                      culture="lv"
                      onChange={this.changeEndDate}
                      value={endDate}
                      disabled={loading}
                      defaultValue={new Date()}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('lvTitle')} */}
                  Title By Latvian
                </Label>
                <Input
                  type="text"
                  name="lvTitle"
                  id="lvTitle"
                  value={lvTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('enTitle')} */}
                  Title By English
                </Label>
                <Input
                  type="text"
                  name="enTitle"
                  id="enTitle"
                  value={enTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">
                  {/* {t('ruTitle')} */}
                  Title By Russian
                </Label>
                <Input
                  type="text"
                  name="ruTitle"
                  id="ruTitle"
                  value={ruTitle}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lvDescription">
                  {/* {t('lvDescription')} */}
                  Description By Latvian
                </Label>
                <Input
                  type="textarea"
                  name="lvDescription"
                  id="lvDescription"
                  value={lvDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="enDescription">
                  {/* {t('enDescription')} */}
                  Description By English
                </Label>
                <Input
                  type="textarea"
                  name="enDescription"
                  id="enDescription"
                  value={enDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruDescription">
                  {/* {t('ruDescription')} */}
                  Description By Russian
                </Label>
                <Input
                  type="textarea"
                  name="ruDescription"
                  id="ruDescription"
                  value={ruDescription}
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  {/* {t('bannerImage')} */}
                  Banner Image
                </Label>
                <div className="banner-image">
                  {
                    !preview && !imageUrl ? (
                      <Label className="banner-image-label">Please upload the banner image for event.</Label>
                    ) : (
                      <img className="event-banner-image" src={preview || imageUrl} alt="Preview" />
                    )
                  }
                </div>
                {!showCrop && (
                <div className="event-banner-edit">
                  <Media onClick={() => { this.handleEditPhoto(showCrop); }} src={pencilImg} className="menu-player-edit-image" disabled={loading} />
                </div>
                )}
                {!showCrop && (
                <div className="event-banner-cancel">
                  <Media onClick={() => { this.setState({ imageUrl: '', preview: '' }); }} src={cancelImg} className="menu-player-edit-image" disabled={loading} />
                </div>
                )}
                {showCrop && (
                <div className="event-banner-edit">
                  <Media onClick={() => { this.handleEditPhoto(showCrop); this.uploadEventFile(); }} src={checkImg} className="menu-player-edit-image" />
                </div>
                )}
                {showCrop && (
                <div className="event-banner-cancel">
                  <Media onClick={() => { this.handleEditPhoto(showCrop); this.setState({ preview: null }); }} src={cancelImg} className="menu-player-edit-image" />
                </div>
                )}
                {
                  showCrop && (
                  <div>
                    <div className="event-banner-button" onClick={this.handleImageClick}>
                      {/* {t('uploadImageButton')} */}
                      Upload a Image
                    </div>
                    <input
                      type="file"
                      ref={this.hiddenFileInput}
                      onChange={this._handleImageChange}
                      style={{ display: 'none' }}
                      accept="image/*"
                    />
                  </div>
                  )
                }
                <Input
                  type="text"
                  name="imageUrl"
                  id="imageUrl"
                  placeholder=""
                  hidden
                  readOnly
                  value={imageUrl}
                />
              </FormGroup>
              <FormGroup>
                <Label for="type">
                  {/* {t('discountOption')} */}
                  Discount Option
                </Label>
                <Input type="select" name="type" className="discount-option-select" value={type} disabled={loading} onChange={this.changeDiscountOption}>
                  <option className="background-222" value="specialPrice">Special Price</option>
                  <option className="background-222" value="discountPrice">Discount Price</option>
                  <option className="background-222" value="bonus">Bonus</option>
                </Input>
              </FormGroup>
              {console.log(selectedDiscountPackage, specialPrice, "checkSelectedD")}
              <FormGroup>
                {
                  type === constants.SPECIALPRICE ? (
                    <Row>
                      <Col sm="12" style={{borderBottom: '1px solid #fff'}}>
                        <Row>
                          {
                            constants.SHOP_PRODUCT_TYPES && map(constants.SHOP_PRODUCT_TYPES, (item, key) => (
                              <>
                                <Col sm="6">
                                  <FormGroup>
                                    <Input type="checkbox" className="ml-0" name={item.label} onClick={(e) => this.handleSelectPackage(e, item.realPrice)} disabled={loading} checked={includes(selectedDiscountPackage, item.label) || false} readOnly />
                                    <Label for="type" className="ml-5">
                                      {item.productNr}
                                    </Label>
                                    <Input
                                      type="number"
                                      name={item.label}
                                      id={item.label}
                                      disabled={loading || !includes(selectedDiscountPackage, item.label)}
                                      value={includes(selectedDiscountPackage, item.label) ? specialPrice[item.label] : ''}
                                      onChange={this.handleSpecialPrice}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </FormGroup>
                                </Col>
                              </>
                            ))
                          }
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <FormGroup>
                        <Label for="discountPrice">
                          {/* {t('discountPrice')} */}
                          Discount Price
                        </Label>
                        <Input
                          type="number"
                          name="discountPrice"
                          id="discountPrice"
                          disabled={loading}
                          value={discountPrice}
                          onChange={this.handleChange}
                          onWheel={(e) => e.target.blur()}
                        />
                      </FormGroup>
                      <Row>
                        <Col sm="12" style={{borderBottom: '1px solid #fff' }}>
                          <Row>
                            {
                              constants.SHOP_PRODUCT_TYPES && map(constants.SHOP_PRODUCT_TYPES, (item, key) => (
                                <>
                                  <Col sm="6" >
                                    <FormGroup>
                                      <Label className="event-type-input" style={{ marginLeft: 40 }}>
                                        <Input
                                          type="checkbox"
                                          name={item.label}
                                          onClick={this.handleSelectPackage}
                                          disabled={loading}
                                          checked={selectedDiscountPackage?.includes(item.label) || false}
                                          readOnly
                                        />
                                        <span className="checkmark" />
                                        <div className="event-type-text">
                                          {item.productNr}
                                        </div>
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                </>
                              ))
                            }
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )
                }
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleShowInBanner} disabled={loading} checked={showInBanner} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('showInBanner')} */}
                        Would you like to show event in banner Image?
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="event-type-input" style={{ marginLeft: 40 }}>
                      <Input type="checkbox" onClick={this.toggleDevMode} disabled={loading} checked={devMode} readOnly />
                      <span className="checkmark" />
                      <div className="event-type-text">
                        {/* {t('devMode')} */}
                        Only Dev
                      </div>
                    </Label>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="modal-footer-button" disabled={loading || ableToSave} onClick={this.addShopDiscount}>{loading ? 'Loading' : 'Save'} </Button>
            <Button className="modal-footer-button" disabled={loading || ableToSave} onClick={this.closeShopDiscountModal}>{loading ? 'Loading' : 'Close'} </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  allShopDiscounts: state.admin.allShopDiscounts || [],
});

const mapDispatchToProps = {
  fetchAllShopDiscounts: getAllShopDiscounts,
  addAShopDiscount: addShopDiscount,
  supportAMessageFileUpload: supportMessageFileUpload,
  deleteAShopDiscount: deleteShopDiscount,
  editAShopDiscount: editShopDiscount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('admin')(ShopDiscounts));
