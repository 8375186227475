import React, { Fragment, useEffect, useRef, useState } from 'react';
import createRoomIcon from '../../../images/redesign/mobile/icons/create-room-button-icon.svg';
import bonusPageImg from '../../../images/icons/gift.svg';
import shopImg from '../../../images/icons/shoping_cart.webp';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Popover, PopoverBody, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ErrorBoundary } from "react-error-boundary";

import { setNewGameBet, setNewGameSpeed, setNewMinGamesFunc, toggleNewGameParam } from '../../../actions/userSettings';
import { updateAutoMergeRooms } from '../../../actions/member';

import closeImg from '../../../images/icons/close.png';
import proImg from '../../../images/icons/pro.svg';
import speedImg from '../../../images/icons/fast_game.svg';
import unlimitedImg from '../../../images/redesign/common/Room types unlimited.svg';
import lightningImg from '../../../images/icons/fast_game_lightning.svg';

import keyImg from '../../../images/icons/private.svg';
import fourPImg from '../../../images/icons/4_spēlētāji.svg';
import minGamesImg from '../../../images/icons/min_games.svg';
import infoImg from '../../../images/icons/info-icon-3.png';

import buttonClickedSound from '../../../sounds/button.mp3';
import { size } from 'lodash';
import { MENU_NAVIGATION } from '../../../constants/constants';

const GameBetMap = {
  // '1:1': '1:1',
  // '1:5': '1:5',
  '1:10': '1:10',
  '1:25': '1:25',
  '1:50': '1:50',
  '1:100': '1:100',
  '1:250': '1:250',
  '1:500': '1:500',
  '1:1000': '1:1000',
  '1:5000': '1:5000',
  '1:10000': '1:10000',
};

const GameSpeedMap = {
  unlimited: 'Unlimited',
  parasta: 'Parasta',
  atra: 'Ātrā',
  lightning: 'Zibensistaba',
};

const MinGamesMap = {
  1: '1',
  3: '3',
  5: '5',
  10: '10',
  15: '15',
  20: '20',
  25: '25',
};

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  console.log('fallbackRender', error);

  // saveErrorCode(error);

  return (
    <></>
  );
}

const Sidebar = ({
  userSettings,
  uid,
  toggleNewGameParameter,
  lvl,
  autoMergeRooms,
  setAutoMergeRooms,
  fontMode,
  activeTournament,
  finishedTournamentLastRound,
  joinedRoom,
  activeRoom,
  setNewBet,
  setNewSpeed,
  setNewMinGames,
  createRoom,
  setLastPlayerChangeModalClosed,
  setScreenView,
  tournaments,
  myTournamentsData,
  offset,
  showPrivateRoomPassword,
  closePrivateRoomPassword,
  joinedRoomPassword,
  activeTab,
  changeTab,
  lastBonusSpin,
  soundOn,
}) => {
  const { t, i18n } = useTranslation('common');

  const [sidebarHover, setSidebarHover] = useState(false);
  const [gameSpeedChoserOpen, setGameSpeedChoserOpen] = useState(false);
  const [gameBetChoserOpen, setGameBetChoserOpen] = useState(false);
  const [minGamesChoserOpen, setMinGamesChoserOpen] = useState(false);
  const [mergePopoverActive, setMergePopoverActive] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [createRoomClicked, setCreateRoomClicked] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState(null);
  const [errorModalHeader, setErrorModalHeader] = useState(null);
  const [privateRoomConfirm, setPrivateRoomConfirm] = useState(null);

  const [devMode, setDevMode] = useState(false);

  const audioRef = useRef(null);

  React.useEffect(() => {
    audioRef.current = new Audio(buttonClickedSound);
  }, [buttonClickedSound]);

  useEffect(() => {
    const { hostname } = window.location;
    if (hostname.includes('dev.') || hostname.includes('localhost')) {
      setDevMode(true);
    }
  }, []);

  const playButtonSound = () => {
    if (soundOn) {
      if (audioRef.current) {
        audioRef.current.play();
      }
    }
  }

  const toggleParasta = () => {
    playButtonSound()
    toggleNewGameParameter('parasta', uid);
  };

  const toggleG = () => {
    playButtonSound()
    toggleNewGameParameter('G', uid);
  };

  const togglePro = () => {
    playButtonSound()
    toggleNewGameParameter('pro', uid);
  };

  const toggleSmall = () => {
    playButtonSound()
    toggleNewGameParameter('maza', uid);
  };

  const togglePrivate = () => {
    playButtonSound()
    if (userSettings.privateRoom) {
      setPrivateRoomConfirm(false);
      toggleNewGameParameter('privateRoom', uid);
    } else {
      setPrivateRoomConfirm(true);
    }
  };

  const toggleFourPRoom = () => {
    playButtonSound()
    toggleNewGameParameter('fourPRoom', uid);
  };

  const selectBet = (newBet) => {
    playButtonSound()
    setNewBet(newBet, uid);
  };

  const selectSpeed = (newSpeed) => {
    playButtonSound()
    setNewSpeed(newSpeed, uid);
  }

  const selectMinGames = (newMinGames) => {
    playButtonSound()
    setNewMinGames(newMinGames, uid);
  };

  const checkTournamentLine = () => {
    if (size(myTournamentsData) > 0) {
      if (size(tournaments) > 0) {

        if (myTournamentsData.tournamentId) {
          const curTournament = tournaments.find(item => item.id === myTournamentsData.tournamentId);

          if (curTournament && curTournament.currentState === 'registration') {
            if (curTournament && curTournament.startDate && curTournament.startDate - (1000 * 60 * 5) < (Date.now() + offset)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  const createRoomClickedFunc = async () => {
    if (userSettings.pro && lvl < 10) {
      setCreateRoomClicked(false);
      setErrorModalOpen(true);
      setErrorModalText(t('notifications:needLevelForPro'));
      setErrorModalHeader(t('game:message'));
      return null;
    }

    if (activeTournament && !finishedTournamentLastRound) {
      return null;
    }

    const checkTournament = await checkTournamentLine();
    if (checkTournament) {
      setCreateRoomClicked(false);
      setErrorModalOpen(true);
      setErrorModalText(t('notifications:inActiveTournament'));
      setErrorModalHeader(t('common:home.confirm'));
      return null;
    }

    const {
      parasta, G, pro, maza, privateRoom, bet, minGames, fourPRoom, speed,
    } = userSettings;

    if (!createRoomClicked) {
      setCreateRoomClicked(true);

      playButtonSound()

      if (privateRoom) {
        await createRoom(parasta, G, null, pro, bet, maza, privateRoom, minGames || '1', fourPRoom, speed, (autoMergeRooms && devMode)).then((res) => {
          console.log('createRoom res', res);

          setLastPlayerChangeModalClosed(false);
          setCreateRoomClicked(false);
        }).catch((err) => {
          console.log('createRoom err', err);

          setLastPlayerChangeModalClosed(false);
          setCreateRoomClicked(false);
        });
      } else {
        await createRoom(parasta, G, null, pro, bet, maza, false, minGames || '1', fourPRoom, speed, (autoMergeRooms && devMode)).then((res) => {
          console.log('createRoom res', res);

          setLastPlayerChangeModalClosed(false);
          setCreateRoomClicked(false);
        }).catch((err) => {
          console.log('createRoom err', err);

          setLastPlayerChangeModalClosed(false);
          setCreateRoomClicked(false);
        });
      }

      setScreenView('createRoom');
    }
    return null;
  };

  const confirmTogglePrivate = () => {
    playButtonSound()
    toggleNewGameParameter('privateRoom', uid);
    setPrivateRoomConfirm(false);
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
    setErrorModalText(null);
    setErrorModalHeader(null);
  }

  let digitsArr = [];

  if (joinedRoomPassword) {
    const digits = joinedRoomPassword.toString().split('');
    digitsArr = digits.map((number, index) => ({ number, index }));
  }

  const notSpined = (lastBonusSpin && new Date(lastBonusSpin).setHours(0, 0, 0, 0) !== new Date(Date.now() + offset).setHours(0, 0, 0, 0)) || (!lastBonusSpin);

  return (
    <>
      <div className={`sidebar-old ${sidebarHover && 'sidebar-old-expanded'}`} onMouseEnter={() => setSidebarHover(true)} onMouseLeave={() => setSidebarHover(false)}>
        <div className="room-part start-new-game-sidebar">
          <div className="room-part-new-label">{t('newGame.newGame')}</div>
          <div className="room-part-icon">
            <Media src={createRoomIcon} />
          </div>
          <div className="room-part-content">
            {(joinedRoom && joinedRoom.globalParams) ? (
              <div className="room-part-content-inner room-part-content-inner-joined">
                <>
                  <div className="room-part-content-inner-joined-title">
                    {t('newGame.activeRoom')}
                  </div>
                  <div className="room-part-content-inner-joined-room-info">
                    <Row>
                      <Col sm={{ size: 10, offset: 0 }}>
                        <div className="created-room-div">
                          {joinedRoom.globalParams.bet}
                        </div>
                        <div className="created-room-div">
                          {joinedRoom.globalParams.gameType === 'P' ? (
                            <Fragment>
                              <div className="gameType">
                                {joinedRoom.globalParams.smallGame ? (
                                  <p className="gameType-text">PM</p>
                                ) : (
                                  <p className="gameType-text">P</p>
                                )}
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <div className="gameType">
                                {joinedRoom.globalParams.smallGame ? (
                                  <p className="gameType-text">MG</p>
                                ) : (
                                  <p className="gameType-text">G</p>
                                )}
                              </div>
                            </Fragment>
                          )}
                        </div>
                        <div className="created-room-div">
                          {joinedRoom.globalParams.privateRoom && (
                            <Fragment>
                              <Media
                                className="label-private"
                                src={keyImg}
                                alt="Privāta"
                              />
                            </Fragment>
                          )}
                        </div>
                        <div className="created-room-div">
                          {joinedRoom.globalParams.fourPRoom && (
                            <Fragment>
                              <Media
                                className="label-private"
                                src={fourPImg}
                                alt="4 Spēlētāji"
                              />
                            </Fragment>
                          )}
                        </div>
                        <div className="created-room-div">
                          <div className="minGames">
                            <Media className="minGames-img" src={minGamesImg} alt="4 Spēlētāji" />
                            <p className="minGames-text-active-room">
                              {joinedRoom.globalParams.minGames ? (
                                joinedRoom.globalParams.minGames
                              ) : (
                                '1'
                              )}
                            </p>
                          </div>
                        </div>
                        <div
                          className="created-room-div"
                          style={{ padding: 0, paddingTop: 5, paddingLeft: 10 }}
                        >
                          {joinedRoom.globalParams.fastGame && (
                            <Fragment>
                              <Media className="label-speed" src={speedImg} alt="Ātrā" />
                            </Fragment>
                          )}
                          {joinedRoom.globalParams.lightningGame && (
                            <Fragment>
                              <Media className="label-speed" src={lightningImg} alt="Lightning" />
                            </Fragment>
                          )}
                          {joinedRoom.globalParams.unlimitedGame && (
                            <Fragment>
                              <Media className="label-speed" src={unlimitedImg} alt="Unlimited" />
                            </Fragment>
                          )}
                        </div>
                        <div className="created-room-div">
                          {joinedRoom.globalParams.proGame && (
                            <Fragment>
                              <Media className="label-pro" src={proImg} alt="Pro" />
                            </Fragment>
                          )}
                        </div>

                      </Col>
                    </Row>
                  </div>

                  <div className="room-part-content-inner-joined-room-password">
                    {digitsArr && digitsArr.length && joinedRoom.globalParams.privateRoom ? (
                      <Fragment>
                        <Row className="created-room-code-title">
                          <Col>{t('newGame.password')}</Col>
                        </Row>
                        <div className="created-room-code">
                          {digitsArr.map(digit => (
                            <div key={`digit-${digit.number}-${digit.index}`} className="room-password-digit">{digit.number}</div>
                          ))}
                        </div>
                      </Fragment>
                    ) : (null)}
                  </div>
                </>
              </div>
            ) : (
              <div className="room-part-content-inner">
                <div className="room-part-content-inner-title">
                  {t('newGame.newGame')}
                </div>
                <div className="room-part-content-inner-game-type">
                  <Row>
                    <Col xs={12} className="game-type-title">
                      {t('common.gameType')}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="6" sm="6" style={{ marginBottom: 10 }}>
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 0 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={toggleParasta}
                          checked={userSettings.parasta}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">{t('roomsTable.regular')}</div>
                      </Label>
                    </Col>
                    <Col xs="6" sm="6" style={{ marginBottom: 10 }}>
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 13, marginBottom: 0 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={toggleG}
                          checked={userSettings.G}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 10 }} />
                        <div className="game-type-text">{t('roomsTable.table')}</div>
                      </Label>
                    </Col>
                    <Col xs="6" sm="6" style={{ marginBottom: 10 }}>
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 0 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={togglePro}
                          checked={userSettings.pro}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">
                          {t('newGame.proGame')}
                        </div>
                      </Label>
                    </Col>
                    <Col xs="6" sm="6" style={{ marginBottom: 10 }}>
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 13, marginBottom: 0 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={toggleSmall}
                          checked={userSettings.maza}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 10 }} />
                        <div className={`game-type-text ${i18n.language === 'ru' ? 'game-type-text-ru' : null}`}>
                          {t('newGame.smallGame')}
                        </div>
                      </Label>
                    </Col>

                    <Col xs="6" sm="6" style={{ marginBottom: 10 }}>
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 0 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={togglePrivate}
                          checked={userSettings.privateRoom}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">{t('newGame.privateRoom')}</div>
                      </Label>
                    </Col>
                    <Col xs="12" sm="12" style={{ marginBottom: 10 }}>
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 0 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={toggleFourPRoom}
                          checked={userSettings.fourPRoom}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />
                        <div className="game-type-text">{t('newGame.fourPRoom')}</div>
                      </Label>
                    </Col>
                  </Row>
                </div>

                <div className="room-part-content-inner-other-settings">
                  <Row className="sng-choose-bet">
                    <Col>
                      <div className="sng-choose-bet-label">
                        {t('common.speed')}
                      </div>

                      <Dropdown
                        className="small"
                        isOpen={gameSpeedChoserOpen}
                        toggle={() => setGameSpeedChoserOpen(!gameSpeedChoserOpen)}
                      >
                        <DropdownToggle caret>
                          {t(`common.${userSettings.speed ? userSettings.speed : 'parasta'}`)}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(GameSpeedMap).map(bKey => (
                            <>
                              {(bKey === 'unlimited' && !devMode) ? (
                                null
                              ) : (
                                <DropdownItem
                                  key={bKey}
                                  onClick={() => {
                                    selectSpeed(bKey);
                                  }}
                                >
                                  {t(`common.${bKey}`)}
                                </DropdownItem>
                              )}
                            </>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
                <div className="room-part-content-inner-other-settings">
                  <Row className="sng-choose-bet">
                    <Col>
                      <div className="sng-choose-bet-label">
                        {t('common.bet')}
                      </div>

                      <Dropdown
                        className="small"
                        isOpen={gameBetChoserOpen}
                        toggle={() => setGameBetChoserOpen(!gameBetChoserOpen)}
                      >
                        <DropdownToggle caret>
                          {GameBetMap[userSettings.bet]}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(GameBetMap).map((bKey) => {
                            if (lvl && lvl >= 3 && (bKey === '1:1' || bKey === '1:5')) {
                              return null;
                            }
                            return (
                              <DropdownItem
                                key={bKey}
                                onClick={() => {
                                  selectBet(bKey);
                                }}
                              >
                                {GameBetMap[bKey]}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
                <div className="room-part-content-inner-other-settings">
                  <Row className="sng-choose-bet">
                    <Col>
                      <div className="sng-choose-bet-label">
                        {t('common.minGames')}
                      </div>

                      <Dropdown
                        className="small"
                        isOpen={minGamesChoserOpen}
                        toggle={() => setMinGamesChoserOpen(!minGamesChoserOpen)}
                      >
                        <DropdownToggle caret>
                          {MinGamesMap[userSettings.minGames ? userSettings.minGames : '1']}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(MinGamesMap).map(minKey => (
                            <DropdownItem
                              key={minKey}
                              onClick={() => {
                                selectMinGames(minKey);
                              }}
                            >
                              {MinGamesMap[minKey]}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>

                <div className="room-part-content-inner-allow-other-rooms">
                  <Row>
                    <Col xs="12">
                      <Label
                        className="game-type-input"
                        style={{ marginLeft: 28, marginBottom: 0 }}
                      >
                        <Input
                          type="checkbox"
                          onClick={() => setAutoMergeRooms(!autoMergeRooms)}
                          checked={!!autoMergeRooms}
                          readOnly
                        />
                        <span className="checkmark" style={{ marginLeft: 25 }} />

                        <div className="game-type-text">
                          <span>{t('roomsTable.autoMergeRooms')}</span>
                          <div id="merge-info-button" onMouseEnter={() => setMergePopoverActive(true)} onMouseLeave={() => setMergePopoverActive(false)} className="game-type-text-info-imageWrapper">
                            <Media className="game-type-text-info-image" src={infoImg} alt="" />
                            {/* <div className={`game-type-text-info-hover ${mergePopoverActive ? null : 'display-none'}`}>
                                                        {t('roomsTable.autoMergeRoomsInfo')}
                                                        </div> */}
                            <ErrorBoundary
                                fallbackRender={fallbackRender}
                            >
                              <Popover container={'div > div'} popperClassName={`popover popover-font-${fontMode}`} placement="top" isOpen={mergePopoverActive} target="merge-info-button">
                                <PopoverBody className="popover-body">
                                  <div>
                                    {t('roomsTable.autoMergeRoomsInfo')}
                                  </div>
                                </PopoverBody>

                              </Popover>
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Label>
                    </Col>
                  </Row>
                </div>

                <div className="room-part-content-inner-start-game">
                  <Row>
                    <ErrorBoundary
                      fallbackRender={fallbackRender}
                    >
                      <Button
                        //  className={`start-game-button ${(activeTournament) && 'disabled'}`}
                        className={`start-game-button ${((activeTournament && !finishedTournamentLastRound) || joinedRoom) && 'disabled'}`}
                        color="link"
                        //  disabled={createRoomClicked}
                        onClick={() => {
                          createRoomClickedFunc();
                        }}
                        id="newGame-button"
                        onMouseEnter={() => setPopoverOpen(true)}
                        onMouseLeave={() => setPopoverOpen(false)}
                      >
                        {t('newGame.startGame')}
                      </Button>
                      {((activeTournament && !finishedTournamentLastRound) && !activeRoom) ? (
                      <Popover container={'div > div'} popperClassName={`popover popover-font-${fontMode}`} placement="top" isOpen={popoverOpen} target="newGame-button">
                        <PopoverBody className="popover-body">
                          <div>
                            {t('common.inTournamentMessage')}
                          </div>
                        </PopoverBody>
                          </Popover>
                        ) : (null)}
                      </ErrorBoundary>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`bonus-part bonus-tab-sidebar ${notSpined && 'bonus-part-not-spined'} ${activeTab === MENU_NAVIGATION.bonusPage && 'bonus-part-active'}`} onClick={() => changeTab(MENU_NAVIGATION.bonusPage)}>
          <Media src={bonusPageImg} className="bonus-part-icon" />
          <div className="bonus-part-content">
            {t('headerRight.dailyBonus')}
          </div>
        </div>
        <div className={`shop-part shop-tab-sidebar ${activeTab === MENU_NAVIGATION.buyPageMoneyPacks && 'shop-part-active'}`} onClick={() => changeTab(MENU_NAVIGATION.buyPageMoneyPacks)}>
          <Media src={shopImg} className="shop-part-icon shop-tab" />
          <div className="shop-part-content">
            {t('headerRight.buy')}
          </div>
        </div>


      </div>
      <Modal
        container={'div > div'}
        isOpen={privateRoomConfirm}
        toggle={() => setPrivateRoomConfirm(false)}
        className={`notification old-modal root-font-${fontMode}`}
      >
        <ModalHeader
          className="notification-header"
          close={(
            <Media
              src={closeImg}
              className="notification-header-close"
              alt="X"
              onClick={() => setPrivateRoomConfirm(false)}
            />
          )}
        >{t('home.confirm')}</ModalHeader>
        <ModalBody className="notification-body">
          {t('newGame.privateRoomConfirm')}
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button
            color="link"
            className="btn notification-footer-button"
            onClick={confirmTogglePrivate}
          >
            {t('common.yes')}
          </Button>
          <Button
            color="link"
            className="btn notification-footer-button"
            onClick={() => setPrivateRoomConfirm(false)}
          >
            {t('common.no')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        container={'div > div'}
        isOpen={!!showPrivateRoomPassword}
        toggle={() => closePrivateRoomPassword()}
        className={`notification old-modal root-font-${fontMode}`}
      >
        <ModalHeader
          className="notification-header"
          close={(
            <Media
              src={closeImg}
              className="notification-header-close"
              alt="X"
              onClick={() => closePrivateRoomPassword()}
            />
          )}
        >
          {t('newGame.yourPassword')}
        </ModalHeader>
        <ModalBody className="notification-body">
          {digitsArr.map(digit => (
            <div key={`digit2-${digit.number}-${digit.index}`} className="room-password-digit">{digit.number}</div>
          ))}
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button
            color="link"
            className="btn notification-footer-button"
            onClick={() => closePrivateRoomPassword()}
          >
            {t('common.ok')}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        container={'div > div'}
        isOpen={errorModalOpen}
        toggle={closeErrorModal}
        className={`notification old-modal root-font-${fontMode}`}
      >
        <ModalHeader
          className="notification-header"
          close={(
            <Media
              src={closeImg}
              className="notification-header-close"
              alt="X"
              onClick={closeErrorModal}
            />
          )}
        >
          {errorModalHeader}
        </ModalHeader>
        <ModalBody className="notification-body">
          {errorModalText}
        </ModalBody>
        <ModalFooter className="notification-footer">
          <Button
            color="link"
            className="btn notification-footer-button"
            onClick={closeErrorModal}
          >
            {t('common.ok')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  let joinedRoom = null;
  if (state.member && state.member.joinedRooms && state.rooms && state.rooms.rooms) {
    joinedRoom = state.rooms.rooms[Object.keys(state.member.joinedRooms)[0]] ? state.rooms.rooms[Object.keys(state.member.joinedRooms)[0]] : null;
  }

  return {
    //  userSettings: state.userSettings || {},
    userSettings: state.userSettings ? (state.userSettings[state.member.uid] || state.userSettings.default || {}) : {},
    soundOn: state.userSettings.soundOn,
    //  rooms: state.rooms.rooms || {},
    lvl: state.member.level,
    uid: state.member.uid,
    activeRoom: state.member.activeRoom,
    activeTournament: state.member.activeTournament || null,
    finishedTournamentLastRound: state.member.finishedTournamentLastRound || null,
    joinedRoomPassword: state.member.joinedRoomPassword || state.rooms.joinedRoomPassword || null,
    joinedRoom,
    tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
    myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
    offset: state.member.offset || 0,
    lastBonusSpin: state.member.lastBonusSpin,
    autoMergeRooms: state.member.autoMergeRooms || false,
  };
};

const mapDispatchToProps = {
  toggleNewGameParameter: toggleNewGameParam,
  setAutoMergeRooms: updateAutoMergeRooms,
  setNewBet: setNewGameBet,
  setNewSpeed: setNewGameSpeed,
  setNewMinGames: setNewMinGamesFunc,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
